import { IAuthWithTimestamp, IUser } from 'src/domain/env/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const signInActions = createAsyncAction(
  'ENV_SIGN_IN_REQUEST',
  'ENV_SIGN_IN_SUCCESS',
  'ENV_SIGN_IN_FAILURE'
)<void, void, Error>();

export const logOutAction = createAction('ENV_LOG_OUT')();

export const resetRequestActions = createAsyncAction(
  'ENV_RESET_REQUEST_REQUEST',
  'ENV_RESET_REQUEST_SUCCESS',
  'ENV_RESET_REQUEST_FAILURE'
)<void, void, Error>();

export const resetPasswordActions = createAsyncAction(
  'ENV_RESET_PASSWORD_REQUEST',
  'ENV_RESET_PASSWORD_SUCCESS',
  'ENV_RESET_PASSWORD_FAILURE'
)<void, void, Error>();

export const completeRegistrationActions = createAsyncAction(
  'ENV_COMPLETE_REGISTRATION_REQUEST',
  'ENV_COMPLETE_REGISTRATION_SUCCESS',
  'ENV_COMPLETE_REGISTRATION_FAILURE'
)<void, void, Error>();

export const updateProfileAction = createAction('UPDATE_PROFILE')<{ user: IUser }>();

export const updateUserEmailAction = createAction('UPDATE_USER_EMAIL')<{ user: IUser }>();

export const getUserProfileActions = createAsyncAction(
  'GET_PROFILE_REQUEST',
  'GET_PROFILE_SUCCESS',
  'GET_PROFILE_FAILURE'
)<void, { user: IUser }, Error>();

export const updateAuthAction = createAction('UPDATE_AUTH')<IAuthWithTimestamp>();
