import React, { FunctionComponent } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';

// components
import Badge from '@material-ui/core/Badge';

type BadgeProps = React.ComponentProps<typeof Badge>;

type StyledBadgeProps = {
  bgColor?: string;
} & BadgeProps;

const StyledBadge: FunctionComponent<StyledBadgeProps> = ({ bgColor = '#44b700', ...other }) => {
  const classes = useStyles({ bgColor });

  return <Badge {...other} classes={{ badge: classes.badge }} />;
};

const useStyles = makeStyles((theme: Theme) => ({
  badge: (props: StyledBadgeProps) => ({
    backgroundColor: props.bgColor,
    color: theme.palette.text.secondary,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  }),
  '@global': {
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  },
}));

export default StyledBadge;
