import React, { Suspense } from 'react';
import Header from '../Header';
import Drawer from '../Drawer';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import useAuth from 'src/hooks/useAuth';
import useToggle from 'src/hooks/useToggle';
import { Switch, Route } from 'react-router';
import routes, { RolesRestriction } from '../routes';
import SpinnerComponent from 'src/components/Spinner';

export interface IWithDrawer {
  open: boolean;
  toggleOpen?: () => void;
}

const Layout = () => {
  const classes = useStyles();
  const { user } = useAuth();

  const [isDrawerOpen, toggleDrawerOpen] = useToggle(false);

  const filterRolesRestrictedRoutes = (rolesRestriction?: RolesRestriction) =>
    !rolesRestriction?.length || (user?.role && rolesRestriction.includes(user.role));

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header open={isDrawerOpen} toggleOpen={toggleDrawerOpen} />
      <main className={classes.content}>
        {/*<div className={classes.toolbar} />*/}
        <Drawer open={isDrawerOpen} toggleOpen={toggleDrawerOpen} />
        <Suspense fallback={<SpinnerComponent />}>
          <Switch>
            {routes
              // Filter routes namespaces (links)
              .filter(({ link }) => !link || filterRolesRestrictedRoutes(link.rolesRestriction))
              .map((item) => {
                if (item.routes) {
                  return (
                    item.routes
                      // Filter specific route
                      .filter(({ rolesRestriction }) =>
                        filterRolesRestrictedRoutes(rolesRestriction)
                      )
                      .map(({ path, component, exact }) => (
                        <Route
                          key={path}
                          exact={Boolean(exact)}
                          path={path}
                          component={component}
                        />
                      ))
                  );
                }
                return null;
              })}
          </Switch>
        </Suspense>
      </main>
    </div>
  );
};

export default Layout;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    minHeight: 'calc(100vh - 64px)',
  },
}));
