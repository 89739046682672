import { AxiosResponse } from 'axios';
import { TApi } from 'src/libs/api';
import { handleHttpError } from 'src/libs/utils';
import { useCallback, useEffect, useState } from 'react';

export interface IFetchWithMeta<T> {
  count: number;
  data: T[];
  page: number;
  pageCount: number;
  total: number;
}

interface IUseFetchResourceParams<T, ServerT = T> {
  api: TApi;
  initialValues?: T; // TODO: make required!
  initialLoad?: boolean;
  initialParams?: object;
  serializer?: (data: ServerT) => T;
}

interface IState<T> {
  resource: T;
  isLoading: boolean;
}

export type TFetchResource<T = any> = (params?: object) => Promise<T | undefined>;
export type TSetResource<T = any> = (setter: (data: T) => void | T) => void;

/**
 * For accept interfaces. Need rework.
 */
const defaultSerializer = <ServerT, T>(data: ServerT): T => JSON.parse(JSON.stringify(data));

/**
 * Use this to load data from api
 */
export default function useFetchResource<T, ServerT = T>({
  api,
  initialValues,
  initialLoad = true,
  initialParams,
  serializer = defaultSerializer,
}: IUseFetchResourceParams<T, ServerT>): IState<T> & {
  fetchResource: TFetchResource<T>;
  setResource: TSetResource<T>;
} {
  const [{ resource, isLoading }, setValues] = useState<IState<T>>({
    isLoading: initialLoad,
    resource: initialValues as T, // CAN BE UNDEFINED
  });

  const fetchResource = useCallback(async (params) => {
    try {
      setValues((state) => ({ ...state, isLoading: true }));
      const response: AxiosResponse<ServerT> = await api(params);
      const serializedData: T = serializer(response.data);
      setValues({ resource: serializedData, isLoading: false });
      return serializedData;
    } catch (error) {
      setValues((state) => ({ ...state, isLoading: false }));
      handleHttpError(error);
    }
  }, []);

  const setResource = useCallback((setter) => {
    setValues((state) => ({
      ...state,
      resource: typeof setter === 'function' ? setter(state.resource) : setter,
    }));
  }, []);

  useEffect(() => {
    if (initialLoad) {
      fetchResource(initialParams);
    }
  }, []);
  return { resource, isLoading, fetchResource, setResource };
}
