import React, { FunctionComponent } from 'react';
import CompleteRegistrationForm from './Form';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import qs from 'qs';
import useOnSubmit from 'src/hooks/useOnSubmit';
import api from 'src/libs/api';
import { required } from 'src/validations/rules/required';
import { passwordConfirm } from 'src/validations/rules/password';
import AuthFormWrapper from '../components/Wrapper';
import { CONFIRM_TOKEN } from 'src/libs/constants';
import { historyPushCallback } from 'src/libs/utils';
import { email } from 'src/validations/rules';

export interface ICompleteRegistrationFormValues {
  password: string;
  confirmationPassword: string;
  email: string;
  token: string;
}

const validationSchema = Yup.object().shape<Omit<ICompleteRegistrationFormValues, 'token'>>({
  password: passwordConfirm,
  confirmationPassword: required(),
  email,
});

const CompleteRegistration: FunctionComponent = () => {
  const location = useLocation();
  const query = qs.parse(location.search.substring(1));

  const onSubmit = useOnSubmit<ICompleteRegistrationFormValues>({
    api: api.auth.completeRegistration,
    onSuccess: historyPushCallback('/sign-in'),
    serializer: (values) => ({ ...values, token: CONFIRM_TOKEN }),
    successMessage: 'Confirmed successfully',
  });

  const initialValues: ICompleteRegistrationFormValues = {
    email: (query?.email as string) || '',
    token: (query?.token as string) || '',
    password: '',
    confirmationPassword: '',
  };

  return (
    <AuthFormWrapper title="Complete registration">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        component={CompleteRegistrationForm}
      />
    </AuthFormWrapper>
  );
};

export default CompleteRegistration;
