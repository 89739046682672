import { AppState } from 'src/domain/reducers';
import api from 'src/libs/api';
import { COOKIES_LIFETIME } from 'src/libs/constants';
import cookies from 'src/libs/cookie';
import { handleHttpError, handleSignInErrors } from 'src/libs/utils';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { logOutAction, signInActions, getUserProfileActions, updateAuthAction } from './actions';
import { envIsAuthorizedSelector } from './selectors';
import { ISignInFormValues } from 'src/pages/Auth/SignIn/Form';
import { FormikHelpers } from 'formik';
import history from 'src/libs/history';
import { DateTime } from 'luxon';
import { IAuth } from './types';

export const thunkUpdateAuth = (
  authData: IAuth
): ThunkAction<void, void, void, Action<string>> => async (dispatch) => {
  const data = { ...authData, timestamp: DateTime.local().toSeconds() };

  dispatch(updateAuthAction(data));

  cookies.set('auth', JSON.stringify(data), {
    domain: process.env.REACT_APP_FRONTEND_DOMAIN,
    expires: new Date(Date.now() + COOKIES_LIFETIME),
    path: '/',
  });
};

export const thunkSignIn = (
  data: ISignInFormValues,
  formActions?: FormikHelpers<ISignInFormValues>
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  try {
    dispatch(signInActions.request());
    const {
      data: { token, refreshToken, user },
    } = await api.auth.signIn({ data });

    dispatch<any>(thunkUpdateAuth({ user, token, refreshToken }));

    dispatch(signInActions.success());

    history.replace('/');
  } catch (e) {
    dispatch(signInActions.failure(e));
    handleSignInErrors<ISignInFormValues>(e, formActions);
  } finally {
    if (formActions) {
      formActions.setSubmitting(false);
    }
  }
};

export const thunkGetUser = (): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch(getUserProfileActions.request());
    const response = await api.auth.profile();
    const auth = { user: response.data };
    dispatch(getUserProfileActions.success(auth));
  } catch (e) {
    dispatch(getUserProfileActions.failure(e));
    handleHttpError(e);
  }
};

export const thunkLogOut = (): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch,
  getState
) => {
  const state = getState();
  const isAuthorized = envIsAuthorizedSelector(state);
  if (isAuthorized) {
    dispatch(logOutAction());
    await cookies.remove('auth', { path: '/' });
  }
};
