import { useCallback, useState } from 'react';

/**
 * For situations when need to toggle between two values.
 */
const useToggle = (initialValue = false): [boolean, () => void] => {
  const [toggled, setToggle] = useState(initialValue);
  const handleToggle = useCallback(() => setToggle((state) => !state), []);

  return [toggled, handleToggle];
};

export default useToggle;
