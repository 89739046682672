import React, { ChangeEvent, FunctionComponent, useMemo } from 'react';
import { FormikHandlers, useField } from 'formik';
import MaterialInput, { TMaterialInput } from './MaterialInput';
import useFormName from 'src/hooks/useFormName';

export interface IInputComponent {
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  value: any;
  name: string;
  placeholder?: string;
  error?: boolean;
}

export interface IInput {
  id?: string;
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  type?: string;
  inputComponent?: FunctionComponent<IInputComponent>;
  materialProps?: TMaterialInput;
  langPrefix?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<{ value: any }>) => void;
  className?: string;
}

/**
 * Default string / number input component
 */
const InputComponent: FunctionComponent<IInput> = ({
  inputComponent: Input = MaterialInput,
  materialProps,
  name,
  langPrefix,
  label,
  disabled,
  ...rest
}) => {
  const { computedName, computedLabel } = useFormName({ name, label, langPrefix });
  const [field, meta] = useField(computedName);

  const errorMessage = useMemo(() => meta.error || '', [meta.error]);

  const hasError = useMemo(() => Boolean(meta.error), [meta.error]);

  return (
    <Input
      {...field}
      {...rest}
      label={computedLabel}
      disabled={disabled}
      errorMessage={errorMessage}
      hasError={hasError}
      {...materialProps}
    />
  );
};

export default InputComponent;
