import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { languageActions } from './actions';
import { TLanguagesAction, TLanguagesState, IServerLanguage, ILanguage } from './types';
import { getIn } from 'formik';
import { TIndexSignature } from 'src/declarations/types';

export const langToCountryCode: TIndexSignature = {
  en: 'gb',
  'es-MX': 'mx',
  zh: 'cn',
};

const getInitialState = () =>
  Immutable({
    error: false,
    loading: false,
    list: [],
    requiredList: [],
  });

const initialState: TLanguagesState = getInitialState();

export const reducer = (state: TLanguagesState = initialState, action: TLanguagesAction) => {
  switch (action.type) {
    case getType(languageActions.request):
      return state.merge(
        {
          error: false,
          loading: true,
        },
        { deep: true }
      );

    case getType(languageActions.failure):
      return state.merge(
        {
          error: true,
          loading: false,
        },
        { deep: true }
      );

    case getType(languageActions.success):
      const list = action.payload.data.map((language: IServerLanguage) => ({
        label: language.name,
        required: language.required,
        value: language.code,
        country: getIn(langToCountryCode, language.code, language.code),
      }));

      const requiredList: ILanguage[] = list?.filter((el) => el.required) || [];

      return state.merge({
        loading: false,
        list,
        requiredList,
      });

    default:
      return state;
  }
};
