import * as Yup from 'yup';

export const createObjectForLanguages = (
  languages: string[],
  rule: (lang?: string) => Yup.InferType<any>,
  setLangToMsgProp = false
) =>
  languages.reduce(
    (acc, lang) => ({
      ...acc,
      [lang]: rule(setLangToMsgProp ? lang : undefined),
    }),
    {}
  ) as any;

export const emptyStringToNull = (value: string | number, originalValue: string | number) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

export const emptyStringToUndefined = (value: string | number, originalValue: string | number) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return value;
};
