import { DEFAULT_LANGUAGE } from './multilangForm';
import { departureStatuses } from './statuses';

export const THEME_PRIMARY_MAIN_COLOR = '#000';

export const LIST_PER_PAGE = 30;

export const WINDOW_WIDTH_SIZES = {
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 576,
};

export const CONFIRM_TOKEN = '12';

export const HTTP_STATUSES = {
  accepted: 202,
  badGateway: 502,
  badRequest: 400,
  conflict: 409,
  continue: 100,
  created: 201,
  expectationFailed: 417,
  failedDependency: 424,
  forbidden: 403,
  found: 302,
  gatewayTimeout: 504,
  gone: 410,
  httpVersionNotSupported: 505,
  imUsed: 226,
  insufficientStorage: 507,
  internalServerError: 500,
  lengthRequired: 411,
  locked: 423,
  methodNotAllowed: 405,
  movedPermanently: 301,
  multiStatus: 207,
  multipleChoices: 300,
  noContent: 204,
  nonAuthoritativeInformation: 203,
  notAcceptable: 406,
  notExtended: 510,
  notFound: 404,
  notImplemented: 501,
  notModified: 304,
  ok: 200,
  partialContent: 206,
  paymentRequired: 402,
  preconditionFailed: 412,
  processing: 102,
  proxyAuthenticationRequired: 407,
  requestEntityTooLarge: 413,
  requestTimeout: 408,
  requestUriTooLong: 414,
  requestedRangeNotSatisfiable: 416,
  resetContent: 205,
  seeOther: 303,
  serviceUnavailable: 503,
  switchingProtocols: 101,
  temporaryRedirect: 307,
  unauthorized: 401,
  unprocessableEntity: 422,
  unsupportedMediaType: 415,
  upgradeRequired: 426,
  useProxy: 305,
};

export const COOKIES_LIFETIME = 1000 * 60 * 60 * 24 * 365 * 10;

export const RESET_PASSWORD_KEYS = ['accessToken', 'client', 'uid', 'expiry'];
export const RESET_PASSWORD_REDIRECT_PATH = 'reset';

export const TOKEN_KEYS = ['accessToken', 'expiresIn'];

export const EDITOR_DEFAULT_COLORS = [
  'rgb(  0,   0,   0)',
  'rgb(230,   0,   0)',
  'rgb(255, 153,   0)',
  'rgb(255, 255,   0)',
  'rgb(  0, 138,   0)',
  'rgb(  0, 102, 204)',
  'rgb(153,  51, 255)',
  'rgb(255, 255, 255)',
  'rgb(250, 204, 204)',
  'rgb(255, 235, 204)',
  'rgb(255, 255, 204)',
  'rgb(204, 232, 204)',
  'rgb(204, 224, 245)',
  'rgb(235, 214, 255)',
  'rgb(187, 187, 187)',
  'rgb(240, 102, 102)',
  'rgb(255, 194, 102)',
  'rgb(255, 255, 102)',
  'rgb(102, 185, 102)',
  'rgb(102, 163, 224)',
  'rgb(194, 133, 255)',
  'rgb(136, 136, 136)',
  'rgb(161,   0,   0)',
  'rgb(178, 107,   0)',
  'rgb(178, 178,   0)',
  'rgb(  0,  97,   0)',
  'rgb(  0,  71, 178)',
  'rgb(107,  36, 178)',
  'rgb( 68,  68,  68)',
  'rgb( 92,   0,   0)',
  'rgb(102,  61,   0)',
  'rgb(102, 102,   0)',
  'rgb(  0,  55,   0)',
  'rgb(  0,  41, 102)',
  'rgb( 61,  20,  10)',
];

export const X_VERSION = (process.env.REACT_APP_API_VERSION as string).split(/_?/).join('.');

export const DRAWER_WIDTH = 240;

export const TOAST_LIFETIME = 3000;

export const LANGUAGES: { [languageCode: string]: string } = {
  [DEFAULT_LANGUAGE]: 'English',
  es: 'Spanish',
  ru: 'Russian',
  ua: 'Ukrainian',
  it: 'Italian',
  fr: 'French',
  zh: 'Chinese',
  pt: 'Portugal',
  de: 'German',
  'es-MX': 'Mexican Spanish',
};

export const NO_SHOW_REASONS: { [reasoneCode: string]: string } = {
  changedPlans: 'Changed plans',
  badWeather: 'Bad weather',
  arrivedLate: 'Arrived late',
  couldNotFind: 'Couldn’t find starting point / guide',
  other: 'Other',
};

export const MIN_PASSWORD_LETTERS = 6;
export const TOUR_FORM_LIST_IMAGES_COUNT = 5;

export const TOUR_DURATION_MIN_HOURS = 0.5;
export const TOUR_DURATION_MAX_HOURS = 12;
export const TOUR_DURATION_STEP = 0.5;

export const DEPARTURE_ACTIONS_FOR_MARKERS = [
  departureStatuses.ongoing,
  departureStatuses.ready,
  departureStatuses.finished,
];

export const DEFAULT_EMAIL_SUBJECT = 'Message about your booking';

export const ROLE_ADMIN = 'admin';
export const ROLE_SUPERADMIN = 'superadmin';

export const COUNTRIES: { [countryCode: string]: string } = {
  GB: 'United Kingdom',
  ES: 'Spain',
  RU: 'Russia',
  UA: 'Ukraine',
  IT: 'Italy',
  FR: 'France',
  ZH: 'China',
};

export const DEFAULT_CURRENCY = 'USD';

export const DEBOUNCE_DELAY = 500;

export const AUTOCOMPLETE_START_FETCHING_MIN_LETTERS = 2;
export const AUTOCOMPLETE_FETCHING_LIMIT = 15;
export const AUTOCOMPLETE_DEBOUNCE_DELAY = DEBOUNCE_DELAY;
