import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  envRefreshTokenObjectSelector,
  envTimestampSelector,
  envTokenObjectSelector,
  envTokenSelector,
} from 'src/domain/env/selectors';
import { AppState } from 'src/domain/reducers';
import store from 'src/domain/store';
import api from 'src/libs/api';
import { getIn } from 'formik';
import { HTTP_STATUSES, X_VERSION } from 'src/libs/constants';
import Qs from 'qs';
import { IToken } from '../domain/env/types';
import { DateTime } from 'luxon';
import { thunkLogOut, thunkUpdateAuth } from 'src/domain/env/effects';

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/`,
});

http.defaults.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets' });

http.interceptors.request.use(async (config) => {
  const state = store.getState();

  if (!config.params?.isPublic) {
    const tok = await checkToken();
    addAuthenticateHeaderIfTokenExist(state, config, tok);
  }
  addXVersion(config);

  return config;
});

http.interceptors.response.use(undefined, (error) => {
  const state = store.getState();

  logoutOnUnauthorizedError(state, error);

  return Promise.reject(error);
});

/**
 * Check is token is expired
 * @param tokenData
 * @param timestamp
 */
const isTokenExpired = (tokenData: IToken, timestamp: number) => {
  if (tokenData?.expiresIn) {
    return DateTime.local().toSeconds() > timestamp + tokenData.expiresIn;
  }
  return true;
};

/**
 * Add x-version header
 * @param config
 */
function addXVersion(config: AxiosRequestConfig) {
  config.headers = Object.assign(config.headers, { 'x-version': X_VERSION });
}

/**
 * Add Authorization token if token exists
 * @param state
 * @param config
 * @param token
 */
function addAuthenticateHeaderIfTokenExist(
  state: AppState,
  config: AxiosRequestConfig,
  token?: string
) {
  const tokenString = token || envTokenSelector(state);
  if (tokenString) {
    config.headers = Object.assign(config.headers, { Authorization: `Bearer ${tokenString}` });
  }
}

/**
 * Logout if 401 error
 * @param state
 * @param error
 */
function logoutOnUnauthorizedError(state: AppState, error: AxiosError) {
  if (state.env.isAuthorized && getIn(error, 'response.status') === HTTP_STATUSES.unauthorized) {
    store.dispatch<any>(thunkLogOut());
  }
}

/**
 * Map with all refresh tokens
 */
const currentRefreshes = new Map();

/**
 * Refreshing token with refresh token. Checking to avoid duplicate requests
 * @param refreshToken
 */
function tryToRefreshToken(refreshToken: string) {
  try {
    let resolver = currentRefreshes.get(refreshToken);
    if (!resolver) {
      resolver = api.auth.refreshToken({
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          'x-version': X_VERSION,
        },
        params: { isPublic: true },
      });
      currentRefreshes.set(refreshToken, resolver);
    }
    return resolver;
  } catch (error) {
    console.log('Error with refreshing token: ', error);
  }
}

/**
 * Check if refreshToken isn't expired & accessToken exist and expired to refresh accessToken and set new auth data
 */
async function checkToken() {
  const state = store.getState();
  const tokenObject = envTokenObjectSelector(state);
  const refreshTokenObject = envRefreshTokenObjectSelector(state);
  const timestamp = envTimestampSelector(state);

  if (isTokenExpired(refreshTokenObject, timestamp)) {
    store.dispatch<any>(thunkLogOut());
  }

  if (tokenObject.accessToken && isTokenExpired(tokenObject, timestamp)) {
    const { data } = await tryToRefreshToken(refreshTokenObject.accessToken);
    store.dispatch<any>(thunkUpdateAuth(data));

    return data?.token?.accessToken;
  }
}

export default http;
