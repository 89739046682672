import { useCallback, useMemo, useState } from 'react';

const useHeader = (): {
  anchorEl: any;
  isMenuOpen: boolean;
  handleMenu: (event: any) => void;
  handleClose: () => void;
} => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  return { anchorEl, isMenuOpen, handleMenu, handleClose };
};

export default useHeader;
