export const userStatuses = {
  active: 'active',
  pending: 'pending',
  locked: 'locked',
  no_payment_info: 'no_payment_info',
};

export const bundleGuideStatuses = {
  suggested: 'suggested',
  assigned: 'assigned',
  re_offered: 're_offered',
};

export const bundleStatuses = {
  unassigned: 'unassigned',
  suggested: 'suggested',
  unsuggested: 'unsuggested',
  assigned: 'assigned',
  reconfirming: 'reconfirming',
  reconfirmed: 'reconfirmed',
  completed: 'completed',
};

export const bundlePatternStatuses = {
  active: 'active',
  inactive: 'inactive',
};

export const departureStatuses = {
  upcoming: 'upcoming',
  awaiting: 'awaiting',
  ready: 'ready',
  ongoing: 'ongoing',
  finished: 'finished',
  completed: 'completed',
};

export const bookingStatuses = {
  pending: 'pending',
  checked_in: 'checked_in',
  no_show: 'no_show',
};

export const tourStatuses = {
  active: 'active',
  unpublished: 'unpublished',
};

export const invoiceStatuses = {
  issued: 'issued',
  smallAmount: 'small_amount',
  paid: 'paid',
  fail: 'fail',
  cancelled: 'cancelled',
  forbidPayment: <string[]>[],
};
invoiceStatuses.forbidPayment.push(invoiceStatuses.paid, invoiceStatuses.cancelled);


export const DEPARTURE_CONDITION_CANCELLED = 'cancelled';
export const GUIDE_CONDITION_DISABLED = 'disabled';

export const guideRestrictions = {
  acceptBundles: 1,
  readyToDeparture: 2,
  all: 3,
};
