import { DateTime, DurationUnit, DateTimeOptions } from 'luxon';

export const DF_FORMAT = 'YYYY-MM-DD';
export const FORMAT_TIME_SIMPLE = 'HH:mm';
export const FORMAT_DATE_SIMPLE = 'LLL dd, yyyy';
export const FORMAT_DATE_TIME = 'LLL dd, yyyy HH:mm';

export const timeSecondsToDatetime = (seconds: number) => DateTime.fromSeconds(seconds);

export const isToday = (date: string) =>
  Math.ceil(DateTime.fromISO(date).diffNow('day').days) === 0;

export const isYesterday = (date: string) =>
  Math.ceil(DateTime.fromISO(date).diffNow('day').days) === -1;

export const timeStringToDatetime = (time: string): DateTime =>
  DateTime.fromFormat(time, FORMAT_TIME_SIMPLE);

// TODO: REFACTOR METHODS
export const getDateObjectFromISO = (date: string, zone?: string): DateTime =>
  DateTime.fromISO(date, { zone });

export const getISODateDiffFromNow = (date: string, unit: DurationUnit = 'seconds'): number =>
  DateTime.fromISO(date).diffNow().as(unit);

export const formatToDate = (date: string): string =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) as string;
export const formatToLocaleDate = (date: string, options?: DateTimeOptions): string =>
  DateTime.fromISO(date, options).toLocaleString(DateTime.DATE_MED) as string;

export const formatToDateWithTZ = (date: string, zone: string): string =>
  DateTime.fromISO(date, { zone }).toLocaleString(DateTime.DATE_MED) as string;

export const formatToTime = (date: string): string =>
  DateTime.fromISO(date).toLocaleString(DateTime.TIME_24_SIMPLE) as string;

export const formatToTimeWithTZ = (date?: string, zone?: string): string => {
  if (!date) {
    return '';
  }
  return DateTime.fromISO(date, { zone }).toLocaleString(DateTime.TIME_24_SIMPLE) as string;
};

export const formatDate = (
  date?: string,
  type?: 'date' | 'time' | 'dateTime',
  dateOptions: DateTimeOptions = {}
) => {
  if (!date) return '-';

  let format = FORMAT_DATE_SIMPLE;

  switch (type) {
    // case 'date':
    //   format = FORMAT_DATE_SIMPLE;
    //   break;
    case 'time':
      format = FORMAT_TIME_SIMPLE;
      break;
    case 'dateTime':
      format = FORMAT_DATE_TIME;
      break;
  }

  return DateTime.fromISO(date, dateOptions).toFormat(format);
};

export const formatWithTime = (date?: string): string =>
  date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL) : '';

export const getUTCDate = (dtm: DateTime) => {
  const { year, month, day } = dtm;
  return DateTime.utc(year, month, day);
};
