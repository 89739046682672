import React, { createContext, FC, useState, useMemo } from 'react';

// hooks
import useTotalOfNew from './hooks/useTotalOfNew';

type Handler = (date: string, callback?: (date: string) => void) => void;

export type ConversationData = {
  handleChangeBadge: Handler;
  revisionDate?: string | null;
  unread: number;
};

export const handleChangeBadge: Handler = (date, callback = (_) => null) => callback(date);
export const defaultRevisionDate = Date().toString();

const ConversationContext = createContext<ConversationData>({
  handleChangeBadge,
  revisionDate: defaultRevisionDate,
  unread: 0,
});

export const ConversationProvider: FC = ({ children }) => {
  const [revisionDate, setRevisionDate] = useState<string>(defaultRevisionDate);

  const unread = useTotalOfNew(revisionDate);

  const conversationPayload: ConversationData = useMemo(
    () => ({
      handleChangeBadge: (revisionDate) => handleChangeBadge(revisionDate, setRevisionDate),
      revisionDate,
      unread,
    }),
    [revisionDate, unread]
  );

  return (
    <ConversationContext.Provider value={conversationPayload}>
      {children}
    </ConversationContext.Provider>
  );
};

export default ConversationContext;
