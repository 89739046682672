import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { CookiesProvider } from 'react-cookie';
import App from 'src/pages/App';
import * as serviceWorker from './serviceWorker';
import store from 'src/domain/store';
import { StoreContext } from 'redux-react-hook';
import { Router } from 'react-router-dom';
import history from 'src/libs/history';
import { ToastContainer } from 'react-toastify';
import { TOAST_LIFETIME } from './libs/constants';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import luxonUtils from '@date-io/luxon';

import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <CookiesProvider>
    <StoreContext.Provider value={store}>
      <Router history={history}>
        <React.StrictMode>
          <ToastContainer autoClose={TOAST_LIFETIME} style={{ fontSize: '17px' }} />
          <MuiPickersUtilsProvider utils={luxonUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </React.StrictMode>
      </Router>
    </StoreContext.Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
