import { getIn } from 'formik';
import { TIndexSignature } from 'src/declarations/types';

export const DEFAULT_LANGUAGE = 'en';
const DEFAULT_VALUE = '';

export interface IConfig {
  fieldName: string;
  valuePath: string;
}

// Add properties to form object
const addProperties = (config: IConfig[], object: any, languageCode: string, data: any) => {
  config.forEach(({ fieldName, valuePath }) => {
    if (!object[fieldName]) {
      object[fieldName] = {};
    }
    object[fieldName][languageCode] = getIn(data, valuePath, DEFAULT_VALUE);
  });
};

// For fetch serializers - form data from translations
export const getFormValuesFromTranslations = (
  translations: any[],
  config: IConfig[],
  multilang?: any
) => {
  const serialized = translations.reduce((acc: any, translation: any) => {
    addProperties(config, acc, translation.languageCode, translation);
    return acc;
  }, {});
  if (multilang) {
    addProperties(config, serialized, DEFAULT_LANGUAGE, multilang);
  }
  return serialized;
};

export interface IConfigFormObjToTransl<T> extends Partial<IConfig> {
  fieldName: string;
  callback?: (formValues: T, lang: string) => string | string[];
}
export type TConfigFOtoTransl<T> = IConfigFormObjToTransl<T> | string[];

// For submit serializers - form data to translations
export const formObjectToTranslations = <T>(
  values: T,
  languages: string[],
  config: TConfigFOtoTransl<T>[]
) =>
  languages.map((lang) =>
    config.reduce(
      (acc: TIndexSignature<string | string[]>, configItem) => {
        if (typeof configItem === 'string') {
          acc[configItem] = getIn(values, [...(configItem as string).split('.'), lang]);
        } else {
          const { fieldName, valuePath, callback } = configItem as IConfigFormObjToTransl<T>;
          const path = valuePath?.split('.') || [fieldName];
          acc[fieldName] = callback ? callback(values, lang) : getIn(values, [...path, lang]);
        }
        return acc;
      },
      { languageCode: lang }
    )
  );
