import { AppState } from 'src/domain/reducers';
import api from 'src/libs/api';
import { handleHttpError } from 'src/libs/utils';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { settingActions } from './actions';

export const thunkGetSettings = (): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch(settingActions.request());
    const response = await api.dictionary.settings();
    dispatch(settingActions.success(response.data));
  } catch (e) {
    dispatch(settingActions.failure(e));
    handleHttpError(e);
  }
};
