import React, { FunctionComponent } from 'react';
import ResetConfirmForm from './Form';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import useOnSubmit from 'src/hooks/useOnSubmit';
import api from 'src/libs/api';
import { passwordConfirm } from 'src/validations/rules/password';
import { required } from 'src/validations/rules/required';
import AuthFormWrapper from '../components/Wrapper';
import { historyPushCallback } from 'src/libs/utils';

export interface IResetConfirmFormValues {
  password: string;
  confirmationPassword: string;
  token: string;
  id: string;
}

const validationSchema = Yup.object().shape<Omit<IResetConfirmFormValues, 'token' | 'id'>>({
  password: passwordConfirm,
  confirmationPassword: required(),
});

const ResetConfirm: FunctionComponent = () => {
  const { id, token } = useParams();

  const initialValues: IResetConfirmFormValues = {
    password: '',
    confirmationPassword: '',
    id,
    token,
  };

  const onSubmit = useOnSubmit<IResetConfirmFormValues>({
    api: api.auth.resetPasswordConfirm,
    onSuccess: historyPushCallback('/sign-in'),
    successMessage: 'Password changed successfully',
  });

  return (
    <AuthFormWrapper title="Reset password">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        component={ResetConfirmForm}
        validateOnBlur={false}
      />
    </AuthFormWrapper>
  );
};

export default ResetConfirm;
