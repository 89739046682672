import React, { FunctionComponent, ReactNode } from 'react';

import StyledBadge from '../StyledBadge';

type StyledBadgeProps = React.ComponentProps<typeof StyledBadge>;
export type BadgesType = Array<Partial<StyledBadgeProps>>;

interface ComplexBadgeProps {
  badges: BadgesType;
}

/**
 * Chat notification badge
 */
const ComplexBadge: FunctionComponent<ComplexBadgeProps> = ({ children, badges }) => (
  <>
    {badges.length > 0
      ? badges.reduce(
          (child: ReactNode, props: StyledBadgeProps) => (
            <StyledBadge overlap="circle" {...props}>
              {child}
            </StyledBadge>
          ),
          children
        )
      : children}
  </>
);

export default ComplexBadge;
