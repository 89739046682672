import React, { FunctionComponent } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box, { BoxProps } from '@material-ui/core/Box';

interface IFormSubmitButton {
  boxProps?: BoxProps;
  buttonProps?: ButtonProps;
}

const WrappedSubmitButton: FunctionComponent<IFormSubmitButton> = ({
  boxProps,
  buttonProps,
  children,
}) => (
  <Box display="flex" justifyContent="center" mt={1} width="100%" {...boxProps}>
    <Button variant="contained" color="primary" type="submit" {...buttonProps}>
      {children}
    </Button>
  </Box>
);

export default WrappedSubmitButton;
