import {
  Channel,
  Message,
  MessageType,
  Status,
  Type,
  User,
  Role,
  Statistic,
  ChannelStatistic,
  UserStatistic,
} from 'src/entities/channel';

// utils
import fire, { Props } from './firebase';
import unique from './unique';
import { timeSecondsToDatetime, formatDate, isToday, isYesterday } from './date';
import groupBy from 'lodash.groupby';

// constants
const CHANNELS_COLLECTION = 'channels';
const STATISTICS_COLLECTION = 'statistics';
const MESSAGES_COLLECTION = 'messages';
const USERS_COLLECTION = 'users';
const SUPER_ADMIN_ID = 'super.admin@some.com';
const SUPER_ADMIN_NAME = 'Super Admin';

// payloads & types
export interface ChannelItem extends Channel {
  date: string;
}

export interface SectionItem {
  title: string;
  data: MessageItem[];
}

export interface MessageItem extends Message {
  time: string;
  date: string;
}

interface MessagePayload {
  message: string;
  type?: MessageType;
  principal?: boolean;
}

interface StatisticPayload extends Partial<Statistic> {}

interface StatisticBuildPayload {
  statistic?: Statistic;
  channelId: string;
  isChat?: boolean;
  isRead?: boolean;
  channels?: ChannelStatistic;
}

interface IdsPayload {
  channelId: string;
  userId: string;
  messageId?: string;
}

interface UserPayload {
  userId: string;
  email: string;
  displayName?: string;
}

const converDate = (seconds: number) => timeSecondsToDatetime(seconds).toJSDate();

const subscribeToChannels = (userId: string, callback: (list: Channel[]) => void) =>
  fire.observe(
    CHANNELS_COLLECTION,
    (snapshot) => {
      const docs: Channel[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        const channel = {
          ...data,
          id: doc.id,
          createdAt: converDate(data.createdAt.seconds),
        } as Channel;

        docs.push(channel);
      });
      callback(docs);
    },
    {
      orderBy: ['createdAt', 'desc'],
      where: [
        ['createdBy', '==', fire.getDocRef(USERS_COLLECTION, userId)],
        ['type', 'in', [Type.CHAT, Type.SUPPORT]],
      ],
    }
  );

const subscribeToСhats = (callback: (list: Channel[]) => void) =>
  fire.observe(
    CHANNELS_COLLECTION,
    (snapshot) => {
      const docs: Channel[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        const channel = {
          ...data,
          id: doc.id,
          createdAt: converDate(data.createdAt.seconds),
        } as Channel;

        docs.push(channel);
      });
      callback(docs);
    },
    {
      orderBy: ['createdAt', 'desc'],
      where: [['type', '==', Type.CHAT]],
    }
  );

const subscribeToChannel = (channelId: string, callback: (doc: Channel) => void) =>
  fire.observeDoc(CHANNELS_COLLECTION, channelId, (doc) => {
    const data = doc.data();
    const channel = {
      ...data,
      id: doc.id,
      createdAt: converDate(data!.createdAt.seconds),
    } as Channel;
    callback(channel);
  });

const subscribeToStatisctic = (statiscticId: string, callback: (doc: Statistic) => void) =>
  fire.observeDoc(STATISTICS_COLLECTION, statiscticId, (doc) => {
    callback({
      ...doc.data(),
    } as Statistic);
  });

const subscribeToUsers = (callback: (list: User[]) => void, where: Props['where'] = []) =>
  fire.observe(
    USERS_COLLECTION,
    (snapshot) => {
      const docs: User[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        const user = {
          ...data,
          id: doc.id,
        } as User;

        docs.push(user);
      });
      callback(docs);
    },
    {
      orderBy: ['displayName', 'asc'],
      where: [['role', '==', Role.GUIDE], ...where],
    }
  );

const subscribeToMessages = (channelId: string, callback: (list: Message[]) => void) =>
  fire.observe(
    `${CHANNELS_COLLECTION}/${channelId}/${MESSAGES_COLLECTION}`,
    (snapshot) => {
      const docs: Message[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        const message = {
          ...data,
          id: doc.id,
          createdAt: converDate(data.createdAt.seconds),
        } as Message;

        docs.push(message);
      });
      docs.length > 0 && callback(docs);
    },
    {
      orderBy: ['createdAt', 'desc'],
      limit: 30,
    }
  );

const getChannelBy = (channelId: string, callback: (item: Channel) => void) => {
  fire.getDocOnce(CHANNELS_COLLECTION, channelId, (doc: any) => {
    if (doc.exists) {
      const data = doc.data();
      const channel = {
        ...data,
        id: doc.id,
        createdAt: converDate(data.createdAt.seconds),
      } as Channel;
      callback(channel);
    }
  });
};

const addUser = async ({ userId, email }: UserPayload) => {
  fire.createOrUpdate(USERS_COLLECTION, userId, {
    email,
    displayName: SUPER_ADMIN_NAME,
    role: Role.ADMIN,
  });
};

const archiveChannel = async (channelId: string) => {
  fire.createOrUpdate(CHANNELS_COLLECTION, channelId, {
    status: Status.ARCHIVE,
  });
};

const removeUser = async (userId: string) => {
  if (!userId) return;

  const condition: Props = {
    where: [['createdBy', '==', fire.getDocRef(USERS_COLLECTION, userId)]],
  };

  // get all channels
  const channelSnapshot = await fire.getCollectionRef(CHANNELS_COLLECTION, condition).get();
  const channels: string[] = [];
  channelSnapshot.forEach((doc) => channels.push(doc.id));

  const statSnapshot = await fire.getDocRef(STATISTICS_COLLECTION, SUPER_ADMIN_ID).get();
  const globalStat = (statSnapshot.exists ? statSnapshot.data() : {}) as Statistic;

  // delete user statistic
  fire.deleteDoc(STATISTICS_COLLECTION, userId);

  // delete all messages
  channels.forEach(
    async (channelId) =>
      await fire.deleteCollection(`${CHANNELS_COLLECTION}/${channelId}/${MESSAGES_COLLECTION}`, {
        limit: 20,
      })
  );

  // delete all channels
  await fire.deleteCollection(CHANNELS_COLLECTION, { ...condition, limit: 20 });

  // delete user
  await fire.deleteDoc(USERS_COLLECTION, userId);

  const filter = <T extends ChannelStatistic | UserStatistic>(
    raw: T = {} as T,
    by: string[] = []
  ) =>
    Object.keys(raw)
      .filter((key) => !by.includes(key))
      .reduce(
        (obj, key: string) => ({
          ...obj,
          [key]: raw[key],
        }),
        {} as T
      );

  const payload: Statistic = {
    channels: filter(globalStat.channels, channels),
    chats: filter(globalStat.chats, channels),
    users: filter(globalStat?.users, [userId]),
  };

  // update admin statistic
  fire.replace(STATISTICS_COLLECTION, SUPER_ADMIN_ID, payload);
};

const unarchiveChannel = async (channelId: string) => {
  fire.createOrUpdate(CHANNELS_COLLECTION, channelId, {
    status: Status.ACTIVE,
  });
};

const getDocBy = <T>(path: string, callback: (item: T) => void) =>
  fire.getDoc(path, (doc: any) => {
    if (doc.exists) {
      const item = {
        ...doc.data(),
        id: doc.id,
      } as T;
      callback(item);
    }
  });

const addMessage = async (
  { userId, channelId, messageId }: IdsPayload,
  { message, type = MessageType.TEXT, principal = true }: MessagePayload,
  withChannel: boolean = false
) => {
  if (!message) {
    return;
  }

  if (!messageId) {
    messageId = await unique();
  }

  const messagePath = `${CHANNELS_COLLECTION}/${channelId}/${MESSAGES_COLLECTION}`;

  fire.createOrUpdate(messagePath, messageId, {
    text: message,
    status: Status.ACTIVE,
    createdAt: new Date(),
    type,
    createdBy: fire.getDocRef(USERS_COLLECTION, userId),
    principal,
  });

  if (!withChannel) {
    fire.createOrUpdate(CHANNELS_COLLECTION, channelId, {
      currentMessage: fire.getDocRef(messagePath, messageId),
    });
  }
};

const compareWith = ({ type }: Channel) => {
  if (type === Type.CHAT) return 1;

  return 2;
};

const updateStatistic = async (statisticId: string, payload: StatisticPayload) => {
  fire.createOrUpdate(STATISTICS_COLLECTION, statisticId, payload);
};

const prepareChannelsToList: (l: Channel[], s: ChannelStatistic) => ChannelItem[] = (
  list = [],
  stat
) => {
  return list
    .map(({ id, createdAt, ...rest }: Channel) => ({
      ...rest,
      id,
      createdAt,
      date: formatDate(createdAt.toISOString(), 'date'),
      unread: stat[id] > 0,
    }))
    .sort((a, b) => +Boolean(b.unread) - +Boolean(a.unread))
    .sort((a, b) => compareWith(a) - compareWith(b));
};

const prepareUsersToList = (list: User[] = [], stat: Statistic) => {
  return list
    .map(({ id, ...rest }: User) => {
      const [chatUnreadCount, channelsUnreadCount] = prepareUnreadUserCounts(stat, id);

      return {
        ...rest,
        id,
        unread: chatUnreadCount > 0 || channelsUnreadCount > 0,
      };
    })
    .sort((a, b) => +Boolean(b.unread) - +Boolean(a.unread));
};

const prepareMessagesToList: (l: Message[]) => SectionItem[] = (list = []) => {
  const grouped = groupBy(list, ({ createdAt }) =>
    isToday(createdAt.toISOString())
      ? 'Today'
      : isYesterday(createdAt.toISOString())
      ? 'Yesterday'
      : formatDate(createdAt.toISOString(), 'date')
  );

  return Object.keys(grouped).map((group) => ({
    title: group,
    data: grouped[group].map(({ createdAt, ...rest }: Message) => ({
      ...rest,
      createdAt,
      time: formatDate(createdAt.toISOString(), 'time'),
      date: formatDate(createdAt.toISOString(), 'date'),
    })),
  }));
};

const getUnreadCount = (list: number[] = []) => {
  return list.reduce((acc, count) => (acc += count), 0);
};

const prepareMessage = ({ createdAt, ...rest }: any) => ({
  ...rest,
  createdAt,
  date: formatDate(converDate(createdAt.seconds).toISOString(), 'date'),
});

const prepareUnreadCounts = (statistic: Statistic) => {
  return [
    getUnreadCount(Object.values(statistic?.chats || [])),
    getUnreadCount(Object.values(statistic?.channels || [])),
  ];
};

const prepareUnreadUserCounts = (statistic: Statistic, userId: string) => {
  const users = statistic.users! || [];
  const chats = statistic.chats || [];
  const channels = statistic.channels || [];

  const user = users[userId] || {};

  const chatId = user.chat;
  const chatUnreadCount = (chatId && chats[chatId!]) || 0;

  const userChannels = (user.channels || []).map((ucId) => channels[ucId]).filter((c) => c > 0);
  const channelsCount = userChannels.length;
  const channelsUnreadCount = getUnreadCount(Object.values(userChannels));

  return [chatUnreadCount, channelsUnreadCount, channelsCount];
};

const prepareUnreadChatCount = (statistic: Statistic, chatId: string) => {
  return (statistic.chats || [])[chatId] || 0;
};

const prepareUnreadChannelCount = (statistic: Statistic, channelId: string) => {
  return (statistic.channels || [])[channelId] || 0;
};

const unreadChannel = (channelId: string, userId: string, isChat: boolean = false) => {
  const payload = {
    ...prepareStatisticChannelPayload({ channelId, isChat }),
    lastVisitedChannel: channelId,
  };

  updateStatistic(userId, payload);
};

const prepareStatisticChannelPayload = ({
  channelId,
  isChat = true,
  isRead = true,
  channels = {},
}: StatisticBuildPayload) => {
  const count = isRead ? 0 : (channels[channelId] || 0) + 1;
  const channel = { [channelId]: count };
  return {
    ...(isChat ? { chats: channel } : { channels: channel }),
  };
};

const prepareStatisticPayload = ({
  statistic,
  channelId,
  isChat = true,
  isRead = true,
}: StatisticBuildPayload) => {
  const channels = isChat ? statistic?.chats : statistic?.channels;

  return prepareStatisticChannelPayload({
    channelId,
    isChat,
    isRead,
    channels,
  });
};

export {
  // constants
  SUPER_ADMIN_ID,
  SUPER_ADMIN_NAME,
  // converters
  prepareChannelsToList,
  prepareMessagesToList,
  prepareUnreadCounts,
  prepareUnreadUserCounts,
  prepareUnreadChannelCount,
  prepareUnreadChatCount,
  prepareStatisticPayload,
  prepareStatisticChannelPayload,
  prepareUsersToList,
  prepareMessage,
  // subscribers
  subscribeToChannels,
  subscribeToMessages,
  subscribeToUsers,
  subscribeToChannel,
  subscribeToStatisctic,
  subscribeToСhats,
  // crud
  getChannelBy,
  addMessage,
  addUser,
  getDocBy,
  updateStatistic,
  // actions
  archiveChannel,
  unarchiveChannel,
  unreadChannel,
  removeUser,
};
