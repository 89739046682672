import { AxiosPromise } from 'axios';
import http from 'src/libs/http';

export type TApi = (params?: object) => AxiosPromise;

/**
 * Function helper returned function that return promise;
 */
function api(method: string, url: ((args: any) => string) | string) {
  return (args?: object) =>
    // @ts-ignore
    http({
      method,
      url: typeof url === 'function' ? url(args) : url,
      ...args,
    });
}

export type TApiMethodNames = 'create' | 'delete' | 'getAll' | 'getOne' | 'update';

/**
 * Returns all CRUD methods for entity
 */
const getRESTMethods = (
  path: string
): { [K in TApiMethodNames]: (args?: object) => AxiosPromise<any> } => ({
  create: api('post', path),
  delete: api('delete', (params) => `${path}/${params.id}`),
  getAll: api('get', path),
  getOne: api('get', (params) => `${path}/${params.id}`),
  update: api('patch', (params) => `${path}/${params.id}`),
});

const Api = {
  auth: {
    forgotPassword: api('post', 'auth/password'),
    resetPasswordRequest: api('post', 'auth/password-reset-request'),
    resetPasswordConfirm: api('post', 'auth/password-reset-confirm'),
    completeRegistration: api('post', 'auth/confirm'),
    changePassword: api('post', 'auth/change-password'),
    profile: api('get', 'auth/profile'),
    resetPassword: api('put', 'auth/password'),
    refreshToken: api('get', 'auth/refresh'),
    signIn: api('post', 'auth/login'),
    signUp: api('post', 'auth/sign_up'),
    changeEMailRequest: api('post', 'auth/change-email-request'),
  },
  admin: {
    ...getRESTMethods('admins'),
    sendInvite: api('post', ({ id }) => `admins/send-invite/${id}`),
  },
  agreement: {
    list: api('get', '/agreement'),
    update: api('patch', ({ id }) => `/agreement/${id}`),
  },
  booking: {
    ...getRESTMethods('booking'),
    cancel: api('post', (params) => `booking/${params.id}/cancel`),
    getCSV: api('get', () => 'booking/csv'),
  },
  bookingPayment: {
    refund: api('post', ({ id }) => `booking-payment/refund/${id}`),
    getManyPDFs: api('post', () => 'booking-payment/invoices/pdfs'),
    invoices: {
      getAll: api('get', 'booking-payment/invoices'),
      getPDF: api('get', ({ id }) => `booking-payment/invoices/pdf/${id}`),
      getCSV: api('get', () => 'booking-payment/invoices/csv'),
    },
  },
  city: {
    ...getRESTMethods('city'),
    deleteTranslation: api('delete', (params) => `city/translation/${params.id}`),
  },
  credit: {
    ...getRESTMethods('credit'),
  },
  customer: {
    anonymize: api('post', ({ id }) => `customer/anonymize/${id}`),
  },
  departure: {
    ...getRESTMethods('departure'),
    getPublicListByTranslationID: api('get', ({ id }) => `departure/public/group-by-date/${id}`),
    cancel: api('post', ({ id }) => `departure/${id}/cancel`),
    merge: api('post', () => 'departure/merge'),
  },
  dictionary: {
    statuses: {
      bundle: api('get', 'dictionary/shift/statuses'),
      bundlePattern: api('get', 'dictionary/shift-pattern/statuses'),
      booking: api('get', 'dictionary/booking/statuses'),
      departure: api('get', 'dictionary/departure/statuses'),
      user: api('get', 'dictionary/user/statuses'),
    },
    settings: api('get', 'dictionary/settings'),
    languages: api('get', 'dictionary/languages'),
  },
  email: {
    customer: {
      send: api('post', (params) => `customer-email/${params.type}/${params.id}`),
      getAll: api('get', 'customer-email'),
    },
  },
  faq: {
    ...getRESTMethods('faq'),
    deleteTranslation: api('delete', (params) => `faq/translation/${params.id}`),
  },
  guide: {
    ...getRESTMethods('guides'),
    deleteTranslation: api('delete', (params) => `guides/translation/${params.id}`),
    sendInvite: api('post', (params) => `guides/send-invite/${params.id}`),
    setDisable: api('post', (params) => `guides/${params.id}/set-disabled`),
    softDelete: api('post', (params) => `guides/${params.id}/soft-delete`),
    findForTour: api('post', 'guides/find-guides-for-tours'),
    getProfile: api('get', ({ id }) => `guides/profile/${id}`),
    setPaymentsPaused:
      (x?: Object) => {
        const { id, paymentsPaused }: { id: number, paymentsPaused: boolean } = <any>x;
        return api('post', () => `guides/${id}/set-payments-paused`)({ data: { paymentsPaused } });
      }
  },
  image: {
    put: api('post', 'upload/image'),
    putMany: api('post', 'upload/image/bulk'),
    putOneChat: api('post', '/upload/chat-image'),
    putManyChat: api('post', '/upload/chat-image/bulk'),
  },
  invoice: {
    getAll: api('get', 'invoice'),
    getPDF: api('get', ({ id }) => `invoice/pdf/${id}`),
    getManyPDFs: api('post', 'invoice/pdfs'),
    getCSV: api('get', () => 'invoice/csv'),
    setCancelled:
      (x?: Object) => {
        const { id, cancelled }: { id: number, cancelled: boolean } = <any>x;
        return api('post', () => `invoice/set-cancelled/${id}`)({ data: { cancelled } });
      }
  },
  iso: {
    countries: api('get', 'iso/country'),
    cities: api('get', 'iso/city'),
    timezones: api('get', 'iso/timezone'),
  },
  location: {
    getCountries: api('get', 'iso/country'),
    getCities: api('get', 'city'),
  },
  meetingPoint: {
    ...getRESTMethods('meeting-point'),
    getAllByCity: api('get', ({ cityId }) => `meeting-point/${cityId}/list-by-city`),
  },
  noShow: {
    getOne: api('get', ({ id }) => `no-show-reason/${id}`),
    getAll: api('get', 'no-show-reason'),
  },
  payment: {
    admin: {
      ...getRESTMethods('payment-admin'),
      // cancel: api('post', ({ id }) => `payment-admin/${id}/cancel`),
      forceCharge: api('post', ({ invoiceId }) => `payment-admin/attempt-by-invoice/${invoiceId}`),
      retry: api('post', ({ id }) => `payment-admin/${id}/retry`),
      // unCancel: api('post', ({ id }) => `payment-admin/${id}/un-cancel`),
    },
    source: {
      ...getRESTMethods('payment-source-admin'),
      getAllFromStripe: api(
        'get',
        ({ guideId }) => `payment-source-admin/list-from-stripe/${guideId}`
      ),
    },
  },
  review: {
    ...getRESTMethods('review'),
    archive: api('post', (params) => `review/${params.id}/soft-delete`),
    reply: api('post', (params) => `review/${params.id}/reply`),
  },
  shiftPattern: getRESTMethods('shift-pattern'),
  shift: {
    ...getRESTMethods('shift'),
    suggestToGuide: api('post', ({ id }) => `shift/${id}/suggest-to-guides`),
    unassign: api('post', ({ id }) => `shift/${id}/un-assign`),
  },
  tip: {
    getAll: api('get', 'city-tip'),
    update: api('patch', (params) => `city-tip/${params.id}`),
  },
  topic: {
    ...getRESTMethods('topic-admin'),
    deleteTranslation: api('delete', (params) => `topic-admin/translation/${params.id}`),
  },
  topicPattern: getRESTMethods('topic-pattern'),
  tour: {
    ...getRESTMethods('tour'),
    deleteTranslation: api('delete', (params) => `tour/translation/${params.id}`),
    availablePoints: api('get', ({ cityId }) => `tour/available-mps/${cityId}`),
  },
  travellerMessage: {
    ...getRESTMethods('traveller-message'),
    reply: api('post', (params) => `traveller-message/${params.id}/reply`),
  },
};

export default Api;
