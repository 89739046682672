import React, { FunctionComponent } from 'react';
import InputComponent from 'src/components/Form/Input';
import Grid from '@material-ui/core/Grid';
import FormikFormWrapper from 'src/components/Form/Wrapper';
import WrappedSubmitButton from 'src/components/Form/SubmitButton/WrappedSubmitButton';
import { FormikProps } from 'formik';
import { ISignInFormValues } from './index';

const SignInForm: FunctionComponent<FormikProps<ISignInFormValues>> = ({ isSubmitting }) => (
  <FormikFormWrapper>
    <Grid container spacing={1}>
      <InputComponent
        type="email"
        name="email"
        id="email"
        placeholder="Email"
        materialProps={{
          required: true,
          autoComplete: 'email',
          autoFocus: true,
        }}
        label="Email Address"
      />
      <InputComponent
        name="password"
        id="password"
        label="Password"
        type="password"
        materialProps={{
          required: true,
          autoComplete: 'current-password',
        }}
      />
      <WrappedSubmitButton buttonProps={{ fullWidth: true, disabled: isSubmitting }}>
        Sign In
      </WrappedSubmitButton>
    </Grid>
  </FormikFormWrapper>
);

export default SignInForm;
