import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { settingActions } from './actions';
import { TSettingsAction, TSettingsState } from './types';

const getInitialState = () =>
  Immutable({
    error: false,
    loading: false,
    data: {
      s3Images: {
        source: '',
        thumbMobile: '',
        thumbWeb: '',
        thumbSquare: '',
      },
    },
  });

const initialState: TSettingsState = getInitialState();

export const reducer = (state: TSettingsState = initialState, action: TSettingsAction) => {
  switch (action.type) {
    case getType(settingActions.request):
      return state.merge(
        {
          error: false,
          loading: true,
        },
        { deep: true }
      );

    case getType(settingActions.failure):
      return state.merge(
        {
          error: true,
          loading: false,
        },
        { deep: true }
      );

    case getType(settingActions.success):
      return state.merge({
        loading: false,
        data: action.payload,
      });

    default:
      return state;
  }
};
