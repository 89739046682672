import React, { FunctionComponent } from 'react';
import ResetRequestForm from './Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useOnSubmit from 'src/hooks/useOnSubmit';
import api from 'src/libs/api';
import AuthFormWrapper from '../components/Wrapper';
import { required } from 'src/validations/rules/required';

export interface IResetRequestFormValues {
  email: string;
}

const validationSchema = Yup.object().shape<IResetRequestFormValues>({
  email: required(),
});

const initialValues: IResetRequestFormValues = { email: '' };

const ResetRequest: FunctionComponent = () => {
  const onSubmit = useOnSubmit<IResetRequestFormValues>({
    api: api.auth.resetPasswordRequest,
    onSuccess: (_data, actions) => {
      actions.resetForm();
    },
    successMessage: 'Reset link sent successfully',
  });

  return (
    <AuthFormWrapper title="Forgot your password?">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        component={ResetRequestForm}
        validateOnBlur={false}
      />
    </AuthFormWrapper>
  );
};

export default ResetRequest;
