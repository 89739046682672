import { useCallback, useMemo } from 'react';
import useFetchResource, { TFetchResource, TSetResource } from './useFetchResource';
import { TApi } from 'src/libs/api';

export interface IServerListResponse<T> {
  count: number;
  data: T[];
  page: number;
  pageCount: number;
  total: number;
}

export interface ITableListData<T> {
  data: T[];
  pageCount: number;
  page: number;
  total?: number;
}

export interface IUseList<T> {
  resources: T[];
  isLoading: boolean;
  totalPages: number;
  page: number;
  total: number;
  fetchResource: TFetchResource<ITableListData<T>>;
  setResource: TSetResource<ITableListData<T>>;
}

interface IUseListProps<T, K> {
  api: TApi;
  serializer: (data: IServerListResponse<K>) => ITableListData<T>;
}

export const tableListInitialValues = {
  data: [],
  pageCount: 0,
  page: 0,
};

/**
 * For fetching data with pagination
 */
export default function useList<T, K = T>({ api, serializer }: IUseListProps<T, K>): IUseList<T> {
  const { resource, isLoading, fetchResource, setResource } = useFetchResource<
    ITableListData<T>,
    IServerListResponse<K>
  >({
    api,
    serializer,
    initialValues: tableListInitialValues,
    initialLoad: false,
  });

  const resources = useMemo(() => resource?.data || [], [resource?.data]);
  const totalPages = useMemo(() => resource?.pageCount || 0, [resource?.pageCount]);
  const page = useMemo(() => resource?.page, [resource?.page]);
  const total = useMemo(() => resource?.total || 0, [resource?.total]);

  return { resources, isLoading, fetchResource, totalPages, page, setResource, total };
}

/**
 * Default table data serializer (returns data with meta)
 */
export const useTableDefaultSerializer = <T>() =>
  useCallback(
    ({ data, page, pageCount, total }: IServerListResponse<T>): ITableListData<T> => ({
      data,
      pageCount,
      page,
      total,
    }),
    []
  );
