import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

interface ISpinnerComponent {
  isHidden?: boolean;
}

const SpinnerComponent: FunctionComponent<ISpinnerComponent> = ({ isHidden = false }) => {
  const classes = useStyles();

  return !isHidden ? (
    <div className={classes.spinner}>
      <LinearProgress />
    </div>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  spinner: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default SpinnerComponent;
