import cookies from 'src/libs/cookie';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import {
  logOutAction,
  signInActions,
  getUserProfileActions,
  updateProfileAction,
  updateUserEmailAction,
  updateAuthAction,
} from './actions';
import { TEnvAction, TEnvState } from './types';

const getInitialState = () => {
  const auth = cookies.get('auth');
  const { token, refreshToken, timestamp } = auth || { token: {}, refreshToken: {}, timestamp: 0 };
  const isAuthorized = Boolean(auth);
  return Immutable({
    error: false,
    isAuthorized,
    loading: false,
    token,
    refreshToken,
    user: {},
    timestamp,
  });
};

const initialState: TEnvState = getInitialState();

export const reducer = (state: TEnvState = initialState, action: TEnvAction) => {
  switch (action.type) {
    case getType(signInActions.request):
      return state.merge(
        {
          error: false,
          loading: true,
        },
        { deep: true }
      );

    case getType(updateProfileAction): {
      return state.merge(
        {
          error: false,
          loading: true,
          user: action.payload.user,
        },
        { deep: true }
      );
    }

    case getType(getUserProfileActions.request):
      return state.merge(
        {
          error: false,
          loading: true,
        },
        { deep: true }
      );

    case getType(getUserProfileActions.success): {
      return state.merge(
        {
          error: false,
          loading: true,
          user: action.payload.user,
        },
        { deep: true }
      );
    }

    case getType(updateUserEmailAction): {
      return state.merge(
        {
          error: false,
          loading: true,
          user: action.payload.user,
        },
        { deep: true }
      );
    }

    case getType(signInActions.failure):
      return state.merge(
        {
          error: true,
          loading: false,
        },
        { deep: true }
      );

    case getType(logOutAction):
      return state.merge({
        error: false,
        isAuthorized: false,
        loading: false,
        token: {},
        user: {},
      });

    case getType(signInActions.success):
      return state.merge({
        isAuthorized: true,
        loading: false,
      });

    case getType(updateAuthAction):
      return state.merge({
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        timestamp: action.payload.timestamp,
        user: action.payload.user,
      });

    default:
      return state;
  }
};
