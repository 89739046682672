import { ref, string, TestMessageParams } from 'yup';
import { MIN_PASSWORD_LETTERS } from 'src/libs/constants';

export const password = string()
  .min(
    MIN_PASSWORD_LETTERS,
    (params: object & Partial<TestMessageParams & { min: number }>): string =>
      `Password is too short(minimum is ${params.min} characters)`
  )
  .required('Required');

export const passwordConfirm = string()
  .oneOf([ref('password')], "Password doesn't match confirmation")
  .required('Password confirm is required');
