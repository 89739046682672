import { AxiosError, AxiosResponse } from 'axios';
import { FormikHelpers, getIn } from 'formik';
// import { HTTP_STATUSES } from 'src/libs/constants';
import history from 'src/libs/history';
import http from 'src/libs/http';
// import isEmpty from 'lodash.isempty';
// import isPlainObject from 'lodash.isplainobject';
import { RefObject } from 'react';
import { LANGUAGES, NO_SHOW_REASONS } from './constants';
import { DEFAULT_LANGUAGE } from './multilangForm';
import { TIndexSignature } from 'src/declarations/types';
import { toast } from 'react-toastify';
import { TSearchParamsObject } from 'src/components/TableFiltersSearch/useFilters';

export interface IErrors {
  [index: string]: string | IErrors;
}

interface IResponseErrors {
  [index: string]: IResponseErrors;
}

interface IResponseError {
  message: string;
  path: string[];
}

interface IDownloadFile {
  url: string;
  filename?: string;
  viewMode?: boolean;
}

// export const checkEmpty = (object: any, defaultValue: any) =>
//   isEmpty(object) ? defaultValue : object;
//
// export function fillEmpty<T>(data: T, values: T): T {
//   (Object.keys(values) as Array<keyof T>).forEach((key) => {
//     data[key] = checkEmpty(data[key], values[key]);
//   });
//   return data;
// }

export const capitalize = (value = ''): string => value.charAt(0).toUpperCase() + value.slice(1);

function makeErrors(errors: IResponseError[]): IErrors {
  return errors.reduce((acc: IErrors, item) => {
    return {
      ...acc,
      [item.path[0]]: item.message,
    };
    // if (isPlainObject(errors[item])) {
    //   acc[item] = makeErrors(errors[item]);
    // } else {
    //   acc[item] = errors[item][0];
    // }
    // return acc;
  }, {});
}

export function handleApiErrors(error: { response: AxiosResponse }): IErrors | false {
  const fieldErrors = error?.response?.data?.errors;
  if (fieldErrors) {
    return makeErrors(fieldErrors);
  }
  return false;
}

// const getMessage = (status: number, data: string[], field?: string): string => {
//   const key = field ? ['errors', field] : ['errors'];
//   if (Array.isArray(getIn(data, key))) {
//     key.push('0');
//   }
//
//   let message = '';
//   switch (status) {
//     case 401:
//     case 403:
//       message = "You're not authorize to this action";
//       break;
//     case 404:
//       message = 'Not found';
//       break;
//     case 422:
//       message = 'Validation error';
//       break;
//     default:
//       message = 'Network error, please try again';
//       break;
//   }
//   return capitalize(getIn(data, key)).trim() || message;
// };

export const handleSignInErrors = <T,>(error: AxiosError, formActions?: FormikHelpers<T>) => {
  if (error?.response?.status === 422 && formActions) {
    formActions.setFieldError('password', 'Wrong email or password');
  } else {
    toast.error('Something went wrong.');
  }
};

export function handleHttpError(error: any): void {
  console.error('error', error?.message);
  // const status = getIn(error, 'response.status');
  // const data = getIn(error, 'response.data');
}

export const getTokens = (keys: string[], getValue: (key: string) => string | null) =>
  keys.reduce((A, key: string) => ({ ...A, [key]: getValue(key) }), {});

export function makeSearchParamsObject(search: URLSearchParams): TSearchParamsObject {
  const values: TIndexSignature<string | string[]> = {};
  for (const key of search.keys()) {
    const allParams = search.getAll(key);
    if (!values[key]) {
      if (allParams.length > 1) {
        values[key] = allParams;
      } else {
        values[key] = getIn(allParams, '0');
      }
    }
  }

  return values;
}

export const downloadFile = ({ url, filename = 'Download.jpg', viewMode }: IDownloadFile) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  if (!viewMode) {
    link.download = filename;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadAuthFile = (url: string, filename: string) => {
  http({
    method: 'GET',
    responseType: 'blob',
    url,
  }).then(({ data }) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    downloadFile({ url: downloadUrl, filename });
  });
};

export const arrayAllUnion = (array: any) =>
  Array.from(new Set(array.reduce((acc: [], item: []) => acc.concat(item))));

export const scrollToBottom = (ref: RefObject<HTMLDivElement>) => {
  if (ref && ref.current) {
    const refBlock = ref.current;
    refBlock.scrollTop = refBlock.scrollHeight;
  }
};
export const historyPushCallback = (path: string) => () => history.push(path);
export const historyReplaceCallback = (path: string) => () => history.replace(path);

export const getRandomInteger = (max: number) => Math.floor(Math.random() * Math.floor(max));

// TODO: make interfaces
export const pick = (object: any, keys: string[]) =>
  keys.reduce((obj: any, key) => {
    if (object?.hasOwnProperty(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});

export const expand = (string: string, value: any = {}) => {
  return string.split('.').reduceRight((acc, currentValue) => {
    return { [currentValue]: acc };
  }, value);
};

export const getLanguageByCode = (languageCode?: string) =>
  LANGUAGES[languageCode || DEFAULT_LANGUAGE];

export const convertLanguages = (langString?: string, withoutFullTitle?: boolean) => {
  if (!langString) {
    return '';
  }
  const languages = langString.split(',');
  return languages
    .map((lang) => {
      const replaced = lang.replace('[', '').replace(']', '');
      return withoutFullTitle ? replaced : LANGUAGES[replaced];
    })
    .join(', ');
};

export const convertStatusFromUppercase = (status?: string) => {
  if (!status) {
    return '';
  }
  return capitalize(status.toLowerCase().replace('_', ' '));
};

export const convertStatusFromCamelcase = (status?: string) => {
  if (!status) {
    return '';
  }
  return capitalize(
    status
      // @ts-ignore
      .replaceAll('_', ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
  );
};

export const convertNoShowReason = (reason?: string) => {
  if (!reason) {
    return '';
  }
  // return NO_SHOW_REASONS[reason] ?? convertStatusFromCamelcase(reason);
  return NO_SHOW_REASONS[reason] || convertStatusFromCamelcase(reason);
};

export const convertBytesToMbsOrKbs = (filesize: number) => {
  let size: string;
  // I know, not technically correct...
  if (filesize >= 1000000) {
    size = filesize / 1000000 + ' megabytes';
  } else if (filesize >= 1000) {
    size = filesize / 1000 + ' kilobytes';
  } else {
    size = filesize + ' bytes';
  }
  return size;
};

export const getFieldByLang = (field: string, lang: string) => field + '_' + lang;

export const getRandomString = (length = 11) =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, length);

export const delay = (ms: number): Promise<undefined> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export function range(from: number, to: number, step = 1): number[] {
  const result = [];
  for (let i = from; i <= to; i += step) {
    result.push(i);
  }
  return result;
}

/**
 * Distinct array of strings or numbers;
 * Use with filter function
 */
export function filterOnlyUnique<T = number | string>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export const convertFAQSlug = (formName?: string) => {
  if (formName) {
    return formName.toLowerCase().replace(/ /g, '_');
  }
  return getRandomString();
};

export const durationFactorRound = (factor?: number, def: string | number = '') =>
  factor ? Math.floor(factor * 1000) / 1000 : def;

export const displayRating = (rating?: string | number | null, def: string | number = '') => {
  if (!rating) {
    return def;
  }
  return Number(rating).toFixed(2);
};

export const formatGDID = (guideId: number, number?: number) =>
  number ? `GDID.${guideId}.${('' + number).padStart(4, '0')}` : '';

export const yesNoBoolean = (value?: string | boolean | null) => (Boolean(value) ? 'Yes' : 'No');
