import { AppState } from 'src/domain/reducers';
import { peek } from 'src/libs/selectors';
import { createSelector } from 'reselect';

const baseSelector = (state: AppState) => state.env;

export const envIsAuthorizedSelector = createSelector(baseSelector, peek('isAuthorized'));

export const envTokenSelector = createSelector(baseSelector, peek('token', 'accessToken'));
export const envRefreshTokenSelector = createSelector(
  baseSelector,
  peek('refreshToken', 'accessToken')
);

export const envTokenObjectSelector = createSelector(baseSelector, peek('token'));
export const envRefreshTokenObjectSelector = createSelector(baseSelector, peek('refreshToken'));

export const envTimestampSelector = createSelector(baseSelector, peek('timestamp'));
export const envUserSelector = createSelector(baseSelector, peek('user'));
