import { ImmutableObject } from 'seamless-immutable';

/**
 * @param {array} path
 * @returns {function(*): (any | *)}
 */
export const peek = (...path: string[]) => (obj: ImmutableObject<{}>) => obj.getIn(path);

/**
 * @param {array} path
 * @param {string} def default value
 * @returns {function(*): (any | *)} or default value
 */
export const peekOr = (path: string[], def: string) => (obj: ImmutableObject<{}>) =>
  obj.getIn(path, def);

export function raw(data: any) {
  return data;
}
