import React, { FunctionComponent, useCallback } from 'react';
import SignInFormLayout from './FormLayout';
import { Formik } from 'formik';
import { thunkSignIn } from 'src/domain/env/effects';
import { useDispatch } from 'redux-react-hook';
import * as Yup from 'yup';
import { required } from 'src/validations/rules/required';
import { email } from 'src/validations/rules';

export interface ISignInFormValues {
  email: string;
  password: string;
}

const initialValues: ISignInFormValues = { email: '', password: '' };

const validationSchema = Yup.object().shape<ISignInFormValues>({
  email,
  password: required(),
});

const SignIn: FunctionComponent = () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values, actions) => {
      dispatch(thunkSignIn(values, actions));
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      component={SignInFormLayout}
      validateOnBlur={false}
    />
  );
};

export default SignIn;
