import { useEffect, useState, useMemo } from 'react';

// config
import { Statistic } from 'src/entities/channel';

// services
import { subscribeToStatisctic, prepareUnreadCounts } from 'src/libs/chat';

export default function (userId: string | undefined, withUnread: boolean = false) {
  const [statistic, setStatistic] = useState<Statistic>({} as Statistic);

  const response: any[] = [statistic];

  if (withUnread) {
    const [chatUnread, , channelsUnreadCount] = useMemo(() => prepareUnreadCounts(statistic), [
      statistic,
    ]);

    response.push(chatUnread, channelsUnreadCount);
  }

  useEffect(() => {
    if (!userId) return;

    return subscribeToStatisctic(userId, setStatistic);
  }, [userId]);

  return response;
}
