import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';

// types
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
export type QuerySnapshot = firebase.firestore.QuerySnapshot;
export type Query = firebase.firestore.Query;
export type Where = [string, firebase.firestore.WhereFilterOp, any];

export interface Props {
  limit?: number;
  where?: Array<Where>;
  orderBy?: [string, 'desc' | 'asc'];
}

class Fire {
  private static instance: Fire;

  private constructor() {
    Fire.init();
  }

  public static getInstance(): Fire {
    if (!Fire.instance) {
      Fire.instance = new Fire();
      Fire.observeAuth();
    }

    return Fire.instance;
  }

  private static init = () => {
    const {
      REACT_APP_FIREBASE_API_KEY,
      REACT_APP_FIREBASE_AUTH_DOMAIN,
      REACT_APP_FIREBASE_DATABASE_URL,
      REACT_APP_FIREBASE_PROJECT_ID,
      REACT_APP_FIREBASE_STORAGE_BUCKET,
      REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      REACT_APP_FIREBASE_APP_ID,
      REACT_APP_FIREBASE_MEASUREMENT_ID,
    } = process.env;

    return firebase.initializeApp({
      apiKey: REACT_APP_FIREBASE_API_KEY,
      authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
      projectId: REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: REACT_APP_FIREBASE_APP_ID,
      measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
    });
  };

  private static observeAuth = () => firebase.auth().onAuthStateChanged(Fire.onAuthStateChanged);

  private static onAuthStateChanged = (user: firebase.User | null) => {
    if (!user) {
      try {
        firebase.auth().signInAnonymously();
      } catch (e) {
        console.log(e);
      }
    }
  };

  get db() {
    return firebase.firestore();
  }

  observe = (path: string, callback: (snapshot: QuerySnapshot) => void, props: Props = {}) =>
    this.getCollectionRef(path, props).onSnapshot(callback as any);

  observeDoc = (path: string, doc: string, callback: (doc: DocumentSnapshot) => void) =>
    this.db
      .collection(path)
      .doc(doc)
      .onSnapshot(callback as any);

  getCollectionOnce = (path: string, callback: any, props: Props = {}) =>
    this.getCollectionRef(path, props).get().then(callback);

  getCollectionRef = (path: string, props: Props = {}) =>
    this.applyProps(this.db.collection(path), props);

  getDocOnce = (path: string, doc: string, callback: any) =>
    this.db.collection(path).doc(doc).get().then(callback);

  getDoc = (path: string, callback: any) => this.db.doc(path).get().then(callback);

  add = (path: string, data: object) => this.db.collection(path).add(data);

  createOrUpdate = (path: string, docName: string, data: object) =>
    this.db.collection(path).doc(docName).set(data, { merge: true });

  getDocRef = (path: string, doc: string) => this.db.collection(path).doc(doc);

  deleteDoc = (path: string, doc: string) => this.db.collection(path).doc(doc).delete();

  replace = (path: string, docName: string, data: object) =>
    this.db.collection(path).doc(docName).set(data);

  deleteCollection = (path: string, props: Props = {}) => {
    const query: Query = this.getCollectionRef(path, props);

    return new Promise((resolve, reject) => {
      this.deleteQueryBatch(query, resolve).catch(reject);
    });
  };

  deleteQueryBatch = async (query: Query, resolve: (value?: unknown) => void) => {
    const snapshot = await query.get();

    const batchSize = snapshot.size;
    if (batchSize === 0) {
      // When there are no documents left, we are done
      resolve();
      return;
    }

    // Delete documents in a batch
    const batch = this.db.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    process.nextTick(() => {
      this.deleteQueryBatch(query, resolve);
    });
  };

  applyProps = (
    ref: firebase.firestore.CollectionReference | firebase.firestore.Query,
    { limit, orderBy, where = [] }: Props
  ) => {
    if (limit) {
      ref = ref.limit(limit);
    }

    if (where.length > 0) {
      where.forEach(([fieldPath, opStr, value]) => {
        ref = ref.where(fieldPath, opStr, value);
      });
    }

    if (orderBy) {
      const [fieldPath, directionStr] = orderBy;
      ref = ref.orderBy(fieldPath, directionStr);
    }

    return ref;
  };
}

export default Fire.getInstance();
