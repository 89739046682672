import { combineReducers } from 'redux';

/**
 * Combine all reducers in this file and export the combined reducers.
 */
import * as env from './env';
import * as languages from './language';
import * as settings from './settings';

export const rootReducer: any = combineReducers({
  env: env.reducer,
  languages: languages.reducer,
  settings: settings.reducer,
});

export type AppState = ReturnType<typeof rootReducer>;
