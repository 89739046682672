import React, { createContext, FC, useEffect } from 'react';
import { Statistic } from 'src/entities/channel';
import { toast } from 'react-toastify';
import { SUPER_ADMIN_ID, prepareUnreadCounts } from 'src/libs/chat';

// hooks
import useAuth from 'src/hooks/useAuth';
import usePrevious from 'src/hooks/usePrevious';
import useStatisticSubscriber from './hooks/useStatisticSubscriber';

const ChatContext = createContext<Statistic>({
  chats: {},
  channels: {},
  users: {},
});

export const ChatProvider: FC = ({ children }) => {
  const { isAuthorized } = useAuth();

  const [statistic] = useStatisticSubscriber(SUPER_ADMIN_ID);
  const prevStatistic = usePrevious(statistic || {});

  useEffect(() => {
    if (isAuthorized) {
      const [chatUnreadCount, channelsUnreadCount] = prepareUnreadCounts(statistic || {});
      const [prevChatUnreadCount, prevChannelsUnreadCount] = prepareUnreadCounts(prevStatistic);
      if (chatUnreadCount > prevChatUnreadCount || channelsUnreadCount > prevChannelsUnreadCount) {
        toast.info('A new chat message has arrived');
      }
    }
  }, [statistic, prevStatistic]);

  return <ChatContext.Provider value={statistic}>{children}</ChatContext.Provider>;
};

export default ChatContext;
