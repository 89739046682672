import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface IAuthFormWrapper {
  title: string;
}

const AuthFormWrapper: FunctionComponent<IAuthFormWrapper> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.formWrapper}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
  },
  wrapper: {
    maxWidth: 700,
    margin: '30px auto',
  },
  formWrapper: {
    width: '60%',
    margin: '0 auto',
  },
}));

export default AuthFormWrapper;
