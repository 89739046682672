export enum MessageType {
  TEXT = 'text',
  PICTURE = 'pic',
  LOCATION = 'location',
}

export enum Type {
  SUPPORT = 'support',
  TRAVELER = 'traveler',
  CHAT = 'chat',
}

export enum Status {
  ARCHIVE = 'archive',
  ACTIVE = 'active',
}

export enum Role {
  GUIDE = 'guide',
  ADMIN = 'admin',
  TRAVELER = 'traveler',
}

export interface User {
  id: string;
  displayName: string;
  email: string;
  channels?: Channel[];
  path: string;
  role: Role;
  unread?: boolean;
}

export interface Message {
  id: string;
  text: string;
  createdAt: Date;
  createdBy: User;
  type: MessageType;
  principal: boolean;
}

export type ChannelStatistic = {
  [key: string]: number;
};

export type UserStatistic = {
  [key: string]: { chat: string; channels: string[] };
};

export type Statistic = {
  chats: ChannelStatistic;
  channels: ChannelStatistic;
  users?: UserStatistic;
  lastVisitedChannel?: string;
};

export interface Channel {
  id: string;
  topic: string;
  status: Status;
  createdAt: Date;
  createdBy: User;
  type: Type;
  currentMessage: { path: string };
  messages: Message[];
  unread?: boolean;
}
