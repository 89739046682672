import { useMemo } from 'react';
import { capitalize } from 'src/libs/utils';

interface IUseFormNameProps {
  name: string;
  label?: string;
  langPrefix?: string;
}

/**
 * Returns computed name (name, language) for multilanguage forms
 */
const useFormName = ({ name, label, langPrefix }: IUseFormNameProps) => {
  const computedName = useMemo(() => (langPrefix ? `${name}.${langPrefix}` : name), [
    name,
    langPrefix,
  ]);

  const computedLabel = useMemo(() => {
    const tempLabel = label ? label : capitalize(name);
    return langPrefix ? `${tempLabel} [${langPrefix}]` : tempLabel;
  }, [name, langPrefix]);

  return { computedName, computedLabel };
};

export default useFormName;
