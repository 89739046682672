import React, { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { Redirect, Route, Switch } from 'react-router';
import SignIn from '../Auth/SignIn';
import ResetRequest from '../Auth/ResetRequest';
import ResetConfirm from '../Auth/ResetConfirm';
import CompleteRegistration from '../Auth/CompleteRegistration';
import useAuth from 'src/hooks/useAuth';
import { thunkGetUser } from 'src/domain/env/effects';
import { thunkGetSettings } from 'src/domain/settings/effects';
import Layout from './Layout';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { THEME_PRIMARY_MAIN_COLOR } from 'src/libs/constants';

// providers
import { ChatProvider } from 'src/pages/Chat/context';

const App = () => {
  const dispatch = useDispatch();
  const { isAuthorized } = useAuth();

  useEffect(() => {
    if (isAuthorized) {
      dispatch(thunkGetUser());
    }

    dispatch(thunkGetSettings());
  }, []);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: THEME_PRIMARY_MAIN_COLOR,
      },
    },
    overrides: {
      MuiSelect: {
        selectMenu: {
          whiteSpace: 'unset',
        },
      },
    },
  });

  return (
    <ChatProvider>
      <MuiThemeProvider theme={theme}>
        <Switch>
          {!isAuthorized && (
            <Switch>
              <Route path="/sign-in" component={SignIn} />
              <Route path="/reset" component={ResetRequest} />
              <Route path="/reset-password/:id/:token" component={ResetConfirm} />
              <Route path="/complete-registration" component={CompleteRegistration} />
              <Redirect to="/sign-in" />
            </Switch>
          )}
          {isAuthorized && <Route path="/" component={Layout} />}
        </Switch>
      </MuiThemeProvider>
    </ChatProvider>
  );
};

export default App;
