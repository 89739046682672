import React, { FunctionComponent } from 'react';
import InputComponent from 'src/components/Form/Input';
import { Grid } from '@material-ui/core';
import FormikFormWrapper from 'src/components/Form/Wrapper';
import WrappedSubmitButton from 'src/components/Form/SubmitButton/WrappedSubmitButton';
import { FormikProps } from 'formik';
import { IResetConfirmFormValues } from './index';

const ResetConfirmForm: FunctionComponent<FormikProps<IResetConfirmFormValues>> = ({
  isSubmitting,
}) => (
  <FormikFormWrapper>
    <Grid container spacing={1}>
      <InputComponent
        type="email"
        name="email"
        id="email"
        placeholder="email@example.com"
        materialProps={{
          required: true,
          autoFocus: true,
        }}
        label="Enter email"
      />
      <InputComponent
        type="password"
        name="password"
        id="password"
        placeholder="Enter password"
        materialProps={{
          required: true,
        }}
        label="New password"
      />
      <InputComponent
        type="password"
        name="confirmationPassword"
        id="confirmationPassword"
        placeholder="Password"
        materialProps={{
          required: true,
        }}
        label="Repeat password"
      />
      <WrappedSubmitButton buttonProps={{ fullWidth: true, disabled: isSubmitting }}>
        Reset
      </WrappedSubmitButton>
    </Grid>
  </FormikFormWrapper>
);

export default ResetConfirmForm;
