import React, { FunctionComponent } from 'react';
import InputComponent from 'src/components/Form/Input';
import FormikFormWrapper from 'src/components/Form/Wrapper';
import { Box, Grid } from '@material-ui/core';
import WrappedSubmitButton from 'src/components/Form/SubmitButton/WrappedSubmitButton';
import { FormikProps } from 'formik';
import { IResetRequestFormValues } from './index';
import { Link as RouterLink } from 'react-router-dom';

const ResetRequestForm: FunctionComponent<FormikProps<IResetRequestFormValues>> = ({
  isSubmitting,
}) => (
  <FormikFormWrapper>
    <Grid container spacing={1}>
      <InputComponent
        type="email"
        name="email"
        id="email"
        placeholder="Email address"
        materialProps={{
          autoComplete: 'email',
          autoFocus: true,
        }}
        label="Send email with instructions to"
      />
      <WrappedSubmitButton buttonProps={{ fullWidth: true, disabled: isSubmitting }}>
        Send me reset link
      </WrappedSubmitButton>
      <Grid container item>
        <Box width="100%" mt={2} display="flex" justifyContent="flex-end">
          <RouterLink type="button" to="/sign-in">
            Sign in
          </RouterLink>
        </Box>
      </Grid>
    </Grid>
  </FormikFormWrapper>
);

export default ResetRequestForm;
