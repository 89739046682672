import React, { lazy, LazyExoticComponent, ReactElement } from 'react';
import {
  AccessTime as DashboardIcon,
  Palette as TourIcon,
  LocationCity as CityIcon,
  EmojiPeople as GuideIcon,
  Extension as BundleIcon,
  List as BookingIcon,
  FlightTakeoff as DepartureIcon,
  VisibilityOff as NoShowIcon,
  RateReview as ReviewIcon,
  AttachMoney as BillingIcon,
  Forum as ChatIcon,
  // Email as ConversationsIcon,
  Settings as SettingIcon,
  PeopleAlt as AdminIcon,
  Receipt as InvoiceIcon,
  AddPhotoAlternate as PatternTemplateIcon,
  CancelScheduleSend as NoShowInvoiceIcon,
} from '@material-ui/icons';
import { IUser } from 'src/domain/env/types';
import { ROLE_SUPERADMIN } from 'src/libs/constants';

export type RolesRestriction = NonNullable<IUser['role']>[];

export interface ILink {
  icon: ReactElement;
  label: string;
  path: string;
  dataTest?: string;
  rolesRestriction?: RolesRestriction; // Links namespace restriction, applied for all nested routes
}

export interface IRenderRoute {
  path: string;
  component: LazyExoticComponent<any>;
  title?: string;
  exact?: boolean;
  rolesRestriction?: RolesRestriction; // Specific route restriction, applied only for one route
}

export interface IRoutes {
  link?: ILink;
  routes?: IRenderRoute[];
  role?: boolean;
}

const routes: IRoutes[] = [
  {
    link: {
      dataTest: 'dashboard',
      icon: <DashboardIcon />,
      label: 'Time clock',
      path: '/',
    },
  },
  {
    link: {
      dataTest: 'booking',
      icon: <BookingIcon />,
      label: 'Bookings',
      path: '/bookings',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Booking/Create')),
        path: '/bookings/create',
        title: 'Create Booking',
      },
      {
        component: lazy(() => import('src/pages/Booking/Edit')),
        path: '/bookings/:id(\\d+)/edit',
        title: 'Booking Edit',
      },
      {
        component: lazy(() => import('src/pages/Booking/Show')),
        path: '/bookings/:id(\\d+)',
        title: 'Booking Show',
      },
      {
        component: lazy(() => import('src/pages/Booking/List')),
        path: '/bookings',
        title: 'Booking',
      },
    ],
  },
  {
    link: {
      dataTest: 'guide',
      icon: <GuideIcon />,
      label: 'Guides',
      path: '/guides',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Guide/Create')),
        path: '/guides/create',
        title: 'Create Guide',
      },
      {
        component: lazy(() => import('src/pages/Guide/Edit')),
        path: '/guides/:id(\\d+)/edit',
        title: 'Guide Edit',
      },
      {
        component: lazy(() => import('src/pages/Guide/Specific')),
        path: '/guides/:id(\\d+)',
        title: 'Guide Show',
      },
      {
        component: lazy(() => import('src/pages/Guide/List')),
        path: '/guides',
        title: 'Guides',
      },
    ],
  },
  {
    link: {
      dataTest: 'city',
      icon: <CityIcon />,
      label: 'Cities',
      path: '/cities',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Topic/Create')),
        path: '/cities/:cityId(\\d+)/topics/create/:tab(\\d+)?',
        title: 'Create Topic',
      },
      {
        component: lazy(() => import('src/pages/Topic/Edit')),
        path: '/cities/:cityId(\\d+)/topics/:id(\\d+)/edit/:tab(\\d+)?',
        title: 'Edit Topic',
      },
      {
        component: lazy(() => import('src/pages/City/Create')),
        path: '/cities/create/:tab(\\d+)?',
        title: 'Create City',
      },
      {
        component: lazy(() => import('src/pages/City/Edit')),
        path: '/cities/:id(\\d+)/edit/:tab(\\d+)?',
        title: 'Edit City',
      },
      {
        component: lazy(() => import('src/pages/City/Specific')),
        path: '/cities/:id(\\d+)/:tab(\\d+)?',
        title: 'City',
      },
      {
        component: lazy(() => import('src/pages/City/List')),
        path: '/cities',
        title: 'Cities',
      },
    ],
  },
  {
    link: {
      dataTest: 'tour',
      icon: <TourIcon />,
      label: 'Tours',
      path: '/tours',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Tour/Create')),
        path: '/tours/create/:tab(\\d+)?',
        title: 'Create Tour',
      },
      {
        component: lazy(() => import('src/pages/Tour/Edit')),
        path: '/tours/:id(\\d+)/edit/:tab(\\d+)?',
        title: 'Edit Tour',
      },
      {
        component: lazy(() => import('src/pages/Tour/Specific')),
        path: '/tours/:id(\\d+)/:tab(\\d+)?',
        title: 'Tour',
      },
      {
        component: lazy(() => import('src/pages/Tour/List')),
        path: '/tours',
        title: 'Tours',
      },
    ],
  },
  {
    link: {
      dataTest: 'bundle-pattern',
      icon: <BundleIcon />,
      label: 'Bundles',
      path: '/bundle-patterns',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/BundlePattern/Create')),
        path: '/bundle-patterns/create/:tab(\\d+)?',
        title: 'Create Bundle',
      },
      {
        component: lazy(() => import('src/pages/BundlePattern/Edit')),
        path: '/bundle-patterns/:id(\\d+)/edit/:tab(\\d+)?',
        title: 'Edit Bundle',
      },
      {
        component: lazy(() => import('src/pages/BundlePattern/List')),
        path: '/bundle-patterns',
        title: 'Bundles',
      },
      {
        component: lazy(() => import('src/pages/Bundle/Specific')),
        path: '/bundles/:id',
        title: 'Bundle',
      },
    ],
  },
  {
    link: {
      dataTest: 'departure',
      icon: <DepartureIcon />,
      label: 'Departures',
      path: '/departures',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Departure/Specific')),
        path: '/departures/:id(\\d+)',
        title: 'Departure',
      },
      {
        component: lazy(() => import('src/pages/Departure/List')),
        path: '/departures',
        title: 'Departures',
      },
    ],
  },
  {
    link: {
      dataTest: 'review',
      icon: <ReviewIcon />,
      label: 'Reviews',
      path: '/reviews',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Review/Edit')),
        path: '/reviews/:id(\\d+)/edit',
        title: 'Review edit',
      },
      {
        component: lazy(() => import('src/pages/Review/List')),
        path: '/reviews',
        title: 'Review',
      },
    ],
  },
  {
    link: {
      dataTest: 'no-show',
      icon: <NoShowIcon />,
      label: 'No Shows',
      path: '/no-show',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/NoShow/Show')),
        path: '/no-show/:id(\\d+)',
        title: 'No Show Details',
      },
      {
        component: lazy(() => import('src/pages/NoShow/List')),
        path: '/no-show',
        title: 'No Shows',
      },
    ],
  },
  {
    link: {
      dataTest: 'chat',
      icon: <ChatIcon />,
      label: 'Chat',
      path: '/chat',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Chat/ChatList')),
        path: '/chat',
        title: 'Tips',
      },
    ],
  },
  {
    link: {
      dataTest: 'billing',
      icon: <BillingIcon />,
      label: 'Billing',
      path: '/tips',
      rolesRestriction: [ROLE_SUPERADMIN],
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Billing/Tip/List')),
        path: '/tips',
        title: 'Tips',
      },
    ],
  },
  {
    link: {
      dataTest: 'invoice',
      icon: <InvoiceIcon />,
      label: 'Invoices',
      path: '/invoices',
      rolesRestriction: [ROLE_SUPERADMIN],
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Billing/Invoice/List')),
        path: '/invoices',
        title: 'Invoices',
      },
    ],
  },
  {
    link: {
      dataTest: 'no-show-invoices',
      icon: <NoShowInvoiceIcon />,
      label: 'No Show Invoices',
      path: '/no-show-invoices',
      rolesRestriction: [ROLE_SUPERADMIN],
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Billing/NoShowInvoice/List')),
        path: '/no-show-invoices',
        title: 'No Show Invoices',
      },
    ],
  },
  {
    link: {
      dataTest: 'admins',
      icon: <AdminIcon />,
      label: 'Admins',
      path: '/admins',
      rolesRestriction: [ROLE_SUPERADMIN],
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Admin/Create')),
        path: '/admins/create',
        title: 'Create Admin',
      },
      {
        component: lazy(() => import('src/pages/Admin/Edit')),
        path: '/admins/:id(\\d+)/edit',
        title: 'Admin Edit',
      },
      {
        component: lazy(() => import('src/pages/Admin/List')),
        path: '/admins',
        title: 'Admins',
      },
    ],
  },
  {
    link: {
      dataTest: 'topic-patterns',
      icon: <PatternTemplateIcon />,
      label: 'Topic Patterns',
      path: '/topic-patterns',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/TopicPattern')),
        path: '/topic-patterns/',
        title: 'Topic Patterns',
      },
    ],
  },
  {
    link: {
      dataTest: 'settings',
      icon: <SettingIcon />,
      label: 'Settings',
      path: '/settings',
    },
    routes: [
      {
        component: lazy(() => import('src/pages/Settings/tabs/FAQ/Create')),
        path: '/settings/faq/create',
      },
      {
        component: lazy(() => import('src/pages/Settings/tabs/FAQ/Edit')),
        path: '/settings/faq/:id(\\d+)/edit',
      },
      {
        component: lazy(() => import('src/pages/Settings/tabs/FAQ/Specific')),
        path: '/settings/faq/:id(\\d+)',
      },
      {
        component: lazy(() => import('src/pages/Settings')),
        path: '/settings/:tab(\\d+)?',
        title: 'Settings',
      },
    ],
  },
  {
    routes: [
      {
        component: lazy(() => import('src/pages/Profile')),
        path: '/profile',
      },
      {
        component: lazy(() => import('src/pages/Billing/Payment/Specific')),
        path: '/payments/:id(\\d+)?',
        title: 'Payment',
      },
      {
        component: lazy(() => import('src/pages/Image/Preview')),
        path: '/images/preview/:link/:type?',
      },
      {
        component: lazy(() => import('src/pages/Dashboard')),
        path: '/',
      },
    ],
  },
];

export default routes;
