import { string, number, array } from 'yup';
import { emptyStringToNull } from 'src/validations/helpers';

// export const required = string().required('Required');

export const required = (message?: string) =>
  string()
    .ensure()
    .required(message || 'Required');

export const requiredEmail = (message?: string) =>
  string()
    .ensure()
    .email()
    .required(message || 'Required');

export const arrayRequired = (message?: string) => array().required(message || 'Required');

export const wysiwygRequired = (message?: string) =>
  string()
    .ensure()
    .transform((value) => value.replace(/<p>(<br>|[ ]*)<\/p>/g, ''))
    .trim()
    .required(message || 'Required');

export const notNegativeNumberRequired = (message?: string) =>
  number()
    .typeError('Must be a number')
    .transform(emptyStringToNull)
    .min(0, `Cannot be negative`)
    .required(message || 'Required')
    .nullable();

export const positiveNumberRequired = (message?: string) =>
  number()
    .typeError('Must be a number')
    .transform(emptyStringToNull)
    .positive(`Must be greater than 0`)
    .required(message || 'Required')
    .nullable();

export const selectNumberValueRequired = (message?: string) =>
  number()
    .transform((value) => (value === 0 ? undefined : value))
    .required(message || 'Required');

export const onlyNumber = () => number().typeError('Must be a number');
