import React, { FunctionComponent } from 'react';
import { Form as FormikForm, FormikConfig, FormikFormProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

export interface IPageForm<T> {
  isEditForm?: boolean;
  onSubmit: FormikConfig<T>['onSubmit'];
  submitButtonText: string;
  title: string;
  initialValues: T;
}

const FormikFormWrapper: FunctionComponent<FormikFormProps> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <FormikForm className={classes.formWrapper} {...props}>
      {children}
    </FormikForm>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export default FormikFormWrapper;
