import { AxiosResponse } from 'axios';
import { FormikErrors, FormikHelpers, getIn } from 'formik';
import { TApi } from 'src/libs/api';
import { capitalize, handleApiErrors } from 'src/libs/utils';
import { DependencyList, useCallback } from 'react';
import { toast } from 'react-toastify';

export interface IUseOnSubmit<F, S> {
  api: TApi;
  params?: object;
  successMessage?: string;
  serializer?: (values: F) => any;
  onSuccess?: (response: S, actions: FormikHelpers<F>) => void;
  deps?: DependencyList;
}

/**
 * For submitting form data
 */
export default function useOnSubmit<F, S = F>({
  api,
  params,
  successMessage,
  serializer = (formValues) => formValues,
  onSuccess,
  deps = [],
}: IUseOnSubmit<F, S>) {
  return useCallback(
    async (formValues: F, actions: FormikHelpers<F>) => {
      try {
        const response: AxiosResponse<S> = await api({ ...params, data: serializer(formValues) });
        if (successMessage) {
          toast.success(successMessage);
        }
        if (onSuccess) {
          onSuccess(response.data, actions);
        }
      } catch (error) {
        // TODO: refactor
        const processedFormErrors = handleApiErrors(error);
        if (processedFormErrors) {
          actions.setErrors(processedFormErrors as FormikErrors<F>);
        } else {
          const msg = getIn(error, ['response', 'data', 'message'], 'Server error');
          toast.error(capitalize(Array.isArray(msg) ? msg[0] || '' : msg));
        }
      } finally {
        actions.setSubmitting(false);
      }
    },
    [serializer, api, ...deps]
  );
}
