import React, { FunctionComponent, useCallback } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField/TextField';

export type TMaterialInput = TextFieldProps & { hasError?: boolean; errorMessage?: string };

const MaterialInput: FunctionComponent<TMaterialInput> = ({
  hasError,
  errorMessage,
  margin = 'normal',
  ...rest
}) => {
  const onKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  return (
    <TextField
      variant="outlined"
      margin={margin}
      fullWidth={true}
      onKeyPress={onKeyPress}
      error={hasError}
      helperText={hasError ? errorMessage : ''}
      {...rest}
    />
  );
};

export default MaterialInput;
