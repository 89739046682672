import {
  envIsAuthorizedSelector,
  envUserSelector,
  envTokenSelector,
} from 'src/domain/env/selectors';
import { useMappedState } from 'redux-react-hook';
import { createStructuredSelector } from 'reselect';
import { IUser } from 'src/domain/env/types';

const selector = createStructuredSelector({
  isAuthorized: envIsAuthorizedSelector,
  token: envTokenSelector,
  user: envUserSelector,
});

export default function useAuth(): { isAuthorized: boolean; token: string; user: IUser } {
  return useMappedState(selector);
}
