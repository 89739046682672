import React, { FunctionComponent } from 'react';
import InputComponent from 'src/components/Form/Input';
import FormikFormWrapper from 'src/components/Form/Wrapper';
import { FormikProps } from 'formik';
import { ICompleteRegistrationFormValues } from './index';
import WrappedSubmitButton from 'src/components/Form/SubmitButton/WrappedSubmitButton';
import Grid from '@material-ui/core/Grid';

const CompleteRegistrationForm: FunctionComponent<FormikProps<ICompleteRegistrationFormValues>> = ({
  isSubmitting,
}) => (
  <FormikFormWrapper>
    <Grid container spacing={1}>
      <InputComponent name="email" label="Email" />
      <InputComponent name="password" type="password" label="Password" />
      <InputComponent name="confirmationPassword" type="password" label="Confirm password" />
      <WrappedSubmitButton buttonProps={{ fullWidth: true, disabled: isSubmitting }}>
        Complete
      </WrappedSubmitButton>
    </Grid>
  </FormikFormWrapper>
);

export default CompleteRegistrationForm;
