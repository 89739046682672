import React, { FunctionComponent, useCallback, useContext } from 'react';
import { Divider, IconButton, List, ListItem } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { DRAWER_WIDTH } from 'src/libs/constants';
import { IWithDrawer } from '../Layout';
import routes from '../routes';
import useAuth from 'src/hooks/useAuth';
import ComplexBadge, { BadgesType } from 'src/components/ComplexBadge';
import useGlobalStatistic from 'src/pages/Chat/hooks/useGlobalStatistic';
import { prepareUnreadCounts } from 'src/libs/chat';
import ConversationContext from 'src/pages/Conversations/context';
import { Link } from 'react-router-dom';

const DrawerMenu: FunctionComponent<IWithDrawer> = ({ open, toggleOpen }) => {
  const classes = useStyles();
  const { user } = useAuth();

  const statistic = useGlobalStatistic();
  const { unread: unreadConversation } = useContext(ConversationContext);

  const renderChatIcon = useCallback((icon, statistic) => {
    const badges: BadgesType = [];
    const [chatUnreadCount] = prepareUnreadCounts(statistic);

    chatUnreadCount > 0 &&
      badges.push({
        variant: 'dot',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });

    return <ComplexBadge badges={badges}>{icon}</ComplexBadge>;
  }, []);

  const renderConversationIcon = useCallback((icon, unreadConversation) => {
    const badges: BadgesType = [];

    if (unreadConversation > 0) {
      badges.push({
        variant: 'dot',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }

    return <ComplexBadge badges={badges}>{icon}</ComplexBadge>;
  }, []);

  const renderIcon = useCallback(
    (label, icon) => {
      switch (label) {
        case 'Chat':
          return renderChatIcon(icon, statistic);
        case 'Conversations':
          return renderConversationIcon(icon, unreadConversation);
        default:
          return icon;
      }
    },
    [statistic, unreadConversation]
  );

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={toggleOpen}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {routes
          .filter(
            (route) =>
              Boolean(route.link) &&
              (!route.link?.rolesRestriction ||
                route.link?.rolesRestriction.includes(user?.role || ''))
          )
          .map(({ link }, index) => (
            <ListItem button component={Link} key={index} to={link!.path}>
              <ListItemIcon className={classes.listItem}>
                {renderIcon(link!.label, link!.icon)}
              </ListItemIcon>
              <ListItemText primary={link!.label} />
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
};

export default DrawerMenu;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  nestedLink: {
    paddingLeft: theme.spacing(10),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) - 2,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  listItem: {
    marginLeft: theme.spacing(1),
  },
}));
